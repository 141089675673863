import { getSignPackage, getWeChatUserInfo } from '@/api/WeChat'
import { LOCAL, LOCAL_URL } from '@/utils/const'
import wx from 'weixin-js-sdk'

export function appInit (agencyGuid, path, guide) {
  let url = ''
  if (guide) {
    url = `${LOCAL}/#${path}?agency_guid=${agencyGuid}&guide=${guide}`
  } else {
    url = `${LOCAL}/#${path}?agency_guid=${agencyGuid}`
  }
  getWeChatUserInfo({
    c_agency_guid: agencyGuid,
    rurl: url,
    turl: LOCAL_URL
  }).then(res => {
    console.log('res.data', res.data)
    window.location.href = res.data
  })
}

let signPackage = null
const wxConfig = (config) => {
  return wx.config({
    debug: false,
    appId: config.appId,
    timestamp: config.timestamp,
    nonceStr: config.nonceStr,
    signature: config.signature,
    jsApiList: ['getLocation', 'chooseImage', 'uploadImage', 'previewImage', 'hideMenuItems', 'updateAppMessageShareData', 'updateTimelineShareData']
    // jsApiList: ['getLocation', 'chooseImage', 'uploadImage', 'previewImage', 'hideMenuItems', 'onMenuShareTimeline'] // 1.3.3
  })
}

export function wxInit () {
  if (signPackage) {
    wxConfig(signPackage)
  } else {
    getSignPackage({
      url: LOCAL + '/'
    }).then(res => {
      if (res.code === 200) {
        signPackage = res.data
        wxConfig(signPackage)
      }
    })
  }
}

export const wxShare = (agencyGuid, path, userGuid) => {
  wx.ready(() => {
    wx.updateAppMessageShareData({
      title: '书香北疆 码上悦读', // 分享标题
      desc: '',
      link: `${LOCAL}/#${path}?agency_guid=${agencyGuid}&guide=${userGuid}`,
      imgUrl: 'https://msyd-1304515158.cos.ap-beijing.myqcloud.com/public/uploads/jpg/210201/a9087914-6a6d-e569-ea4a-bc7e1d795e94-ff8486c0aae28d4177fa8a934c18f45e.jpg',
      success: () => {
        console.log('分享朋友ok')
      }
    })
    wx.updateTimelineShareData({
      title: '书香北疆 码上悦读', // 分享标题
      link: `${LOCAL}/#${path}?agency_guid=${agencyGuid}&guide=${userGuid}`,
      imgUrl: 'https://msyd-1304515158.cos.ap-beijing.myqcloud.com/public/uploads/jpg/210201/a9087914-6a6d-e569-ea4a-bc7e1d795e94-ff8486c0aae28d4177fa8a934c18f45e.jpg',
      success: () => {
        console.log('分享朋友圈ok')
      }
    })
  })
  wx.error((res) => {
    console.log(res)
  })
}
