<template>
  <div class="banner">
    <swiper class="swiper" :options="swiperOption" ref="centerSwiper">
      <swiper-slide
        class="swiper-slide"
        v-for="(item, index) in swiperList"
        :key="index"
      >
        <div class="img-block">
          <img class="slide-img" :src="item.advert_media" alt=""/>
        </div>
        <div class="swiper-desc">
          <div class="desc-content">
            {{ item.advert_desc }}
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <swiper class="left-swiper" :options="leftSwiperOption" ref="leftSwiper">
      <swiper-slide
        class="swiper-no-swiping swiper-slide"
        v-for="(item, index) in leftSwiper"
        :key="index"
      >
        <div class="img-block">
          <img class="slide-img"
               :src="item.advert_media"/>
        </div>
        <div class="swiper-desc">
          <div class="desc-content">{{ item.advert_desc }}</div>
        </div>
      </swiper-slide>
    </swiper>
    <swiper class="right-swiper" :options="rightSwiperOption" ref="rightSwiper">
      <swiper-slide
        class="swiper-no-swiping swiper-slide"
        v-for="(item, index) in rightSwiper"
        :key="index"
      >
        <div class="img-block">
          <img class="slide-img"
               :src="item.advert_media"/>
        </div>
        <div class="swiper-desc">
          <div class="desc-content">{{ item.advert_desc }}</div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import { Toast } from 'vant'
import { getAudios } from '@/api/Resources'
import { appInit } from '@/utils/init'
import { mapActions, mapGetters } from 'vuex'

const swiperTime = 5000
export default {
  name: 'ActivitySwiper',
  props: {
    swiperList: {
      type: Array,
      default: null
    },
    leftSwiper: {
      type: Array,
      default: null
    },
    rightSwiper: {
      type: Array,
      default: null
    }
  },
  computed: {
    ...mapGetters([
      'userGuid',
      'agencyGuid',
      'openid'
    ])
  },
  components: {
    Swiper,
    SwiperSlide
  },
  activated () {
    if (this.swiperList.length) {
      this.active = this.$refs.centerSwiper.$swiper.realIndex
      let nowIndex = this.$refs.centerSwiper.$swiper.realIndex
      setTimeout(() => {
        if (nowIndex === this.$refs.centerSwiper.$swiper.realIndex) {
          this.$refs.centerSwiper.$swiper.animating = false
          this.$refs.centerSwiper.$swiper.slideNext()
        }
      }, swiperTime)
    }
  },
  mounted () {
    this.$nextTick(() => {
      const centerSwiper = this.$refs.centerSwiper.$swiper
      const leftSwiper = this.$refs.leftSwiper.$swiper
      const rightSwiper = this.$refs.rightSwiper.$swiper
      centerSwiper.controller.control = [leftSwiper, rightSwiper]
    })
  },
  data () {
    return {
      swiperOption: {
        loop: true,
        observer: true,
        observeParents: true,
        autoplay: {
          delay: swiperTime,
          disableOnInteraction: false
        },
        on: {
          click: (swiper) => {
            let currentSwiper = this.swiperList[swiper.realIndex]
            if (!currentSwiper) {
              return false
            }
            this.swiperLocation(currentSwiper)
          }
        }
      },
      leftSwiperOption: {
        loop: true,
        observer: true,
        observeParents: true,
        noSwiping: true
      },
      rightSwiperOption: {
        loop: true,
        observer: true,
        observeParents: true,
        noSwiping: true
      }
    }
  },
  methods: {
    swiperLocation (item) {
      if (item.advert_belong_type === 1) {
        this.$router.push('/home/book-detail/' + item.advert_belong_obj + '/电子书')
      } else if (item.advert_belong_type === 2) {
        Toast.loading({
          duration: 0,
          forbidClick: true,
          message: '加载中...'
        })
        getAudios({
          resources_guid: item.advert_belong_obj,
          c_user_guid: this.userGuid
        }).then(res => {
          if (res.code === 200) {
            if (!res.data.list.length) {
              Toast.fail('暂无音频数据')
              return false
            }
            this.selectPlay({
              list: res.data.list,
              index: res.data.current_index
            })
          } else if (res.code === 401) {
            appInit(this.agencyGuid, this.$route.path)
          } else if (res.code === 402) {
            this.$router.push({
              name: 'Login',
              params: {
                link: this.$route.path
              }
            })
          } else {
            Toast.fail(res.msg)
          }
          Toast.clear()
        })
      } else if (item.advert_belong_type === 3) {
        this.$router.push('/home/video-detail/' + item.advert_belong_obj + '/视频')
      } else if (item.advert_belong_type === 4) {
        this.$router.push('/home/activity-detail/活动/' + item.advert_belong_obj)
      } else if (item.advert_belong_type === 5) {
        this.$router.push('/books/books-detail/' + item.advert_belong_obj + '/书单')
      } else if (item.advert_belong_type === 6) {
        this.$router.push('/location/' + encodeURIComponent(item.advert_url_belong_obj) + '?c_agency_guid=' + this.agencyGuid + '&openid=' + this.openid)
      } else if (item.advert_belong_type === 7) {
        window.location.href = item.advert_url_belong_obj
      }
    },
    ...mapActions([
      'selectPlay'
    ])
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.banner
  width 100%
  height 328px
  margin-top 26px
  position relative

  .swiper
    position absolute
    top 0
    left 90px
    z-index 3
    width 570px
    height 328px
    border-radius 10px
    box-shadow 0 0 10px rgba(0, 0, 0, .3)
    overflow hidden

    .swiper-slide
      width 570px
      height 328px

      .img-block
        width 570px
        height 228px

        .slide-img
          width 100%
          height 100%

      .swiper-desc
        display flex
        align-items center
        justify-content center
        width 570px
        height 100px
        background rgba(255, 255, 255, 1)

        .desc-content
          width 540px
          height 60px
          line-height 32px
          font-size 26px
          color rgba(51, 51, 51, 1)
          no-wrap2(2)

  .left-swiper
    position absolute
    top 20px
    left 30px
    z-index 1

  .right-swiper
    position absolute
    top 20px
    right 30px
    z-index 2

  .left-swiper, .right-swiper
    width 500px
    height 290px
    opacity 0.5
    border-radius 10px
    box-shadow 0 0 10px rgba(0, 0, 0, .3)
    overflow hidden

    .swiper-slide
      width 500px
      height 290px

      .img-block
        width 500px
        height 200px

        .slide-img
          width 100%
          height 100%

      .swiper-desc
        display flex
        align-items center
        justify-content center
        width 500px
        height 90px
        background rgba(255, 255, 255, 1)

        .desc-content
          width 480px
          height 60px
          line-height 32px
          font-size 24px
          color rgba(51, 51, 51, 1)
          no-wrap2(2)
</style>
