<template>
  <div class="wrapper">
    <div class="guide"></div>
    <div class="close">
      <div class="close-btn"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Guide'
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.wrapper
  display flex
  flex-wrap wrap
  justify-content center
  width 100%
  .guide
    width 620px
    height 650px
    margin-top 120px
    bg-image('~@/assets/img/home/guide')
    background-size 100% 100%
    background-repeat no-repeat
  .close
    display flex
    justify-content center
    width 100%
    margin-top 120px
    .close-btn
      width 60px
      height 60px
      bg-image('~@/assets/img/sign-in/sign-close')
      background-size 100% 100%
      background-repeat no-repeat
</style>
