import QuestionPopup from './QuestionPopup'

const obj = {}
obj.install = (Vue) => {
  const QuestionPopupConstructor = Vue.extend(QuestionPopup)
  const questionPopup = new QuestionPopupConstructor()
  questionPopup.$mount(document.createElement('div'))
  document.body.appendChild(questionPopup.$el)
  Vue.prototype.$questionPopup = questionPopup
}
export default obj
