<template>
  <div @touchmove.prevent>
    <scroll ref="scroll" class="scroll" :data="data">
      <div>
        <personal-user
          :user-name="userName"
          :user-avatar="userAvatar"
          :user-read-card="userReadCard"
          :read-num="userInfo.c_user_read_num"
          :recommend-num="userInfo.c_user_recommend_num"
        ></personal-user>
               <!-- <personal-content></personal-content> -->
        <personal-read
          v-if="userInfo.log"
          :read-log="userInfo.log.read_log"
          :audio-log="userInfo.log.audio_log"
          :video-log="userInfo.log.video_log"
        ></personal-read>
        <!--        <personal-books></personal-books>-->
        <personal-contact :agency-info="agencyInfo"></personal-contact>
      </div>
    </scroll>
    <router-view></router-view>
  </div>
</template>

<script>
import Scroll from '@/components/scroll/scroll'
import PersonalUser from './components/User'
// import PersonalContent from './components/Content'
import PersonalRead from './components/Read'
// import PersonalBooks from './components/Books'
import PersonalContact from './components/Contact'
import { Toast } from 'vant'
import { getAgencyInfo } from '@/api/Agency'
import { mapGetters } from 'vuex'
import { LOCAL } from '@/utils/const'
import { getCUserInfo } from '@/api/Cuser'

export default {
  name: 'Personal',
  components: {
    Scroll,
    PersonalUser,
    // PersonalContent,
    PersonalRead,
    // PersonalBooks,
    PersonalContact
  },
  computed: {
    ...mapGetters([
      'userName',
      'userAvatar',
      'userReadCard',
      'agencyGuid',
      'userGuid'
    ])
  },
  activated () {
    this._personalInit()
  },
  data () {
    return {
      agencyInfo: {}, // 机构信息
      userInfo: [],
      data: []
    }
  },
  methods: {
    async _personalInit () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      let error = ''
      await Promise.all([
        getAgencyInfo({
          agency_guid: this.agencyGuid,
          url: LOCAL + '/#/home?agency_guid=' + this.agencyGuid
        }).then(res => {
          const { code, msg, data } = res
          if (code === 200) {
            this.agencyInfo = data
          } else {
            error = msg
          }
        }),
        getCUserInfo({
          c_user_guid: this.userGuid
        }).then(res => {
          const { code, msg, data } = res
          if (code === 200) {
            this.userInfo = data
          } else if (res.code === 400) {
            error = msg
          }
        })
      ])
      if (error) {
        Toast.fail(error)
      } else {
        Toast.clear()
      }
      this.refresh()
    },
    refresh () {
      setTimeout(() => {
        this.$refs.scroll.refresh()
      }, 100)
    }
  }
}
</script>

<style lang='stylus' scoped>
.scroll
  position fixed
  top 0
  left 0
  right 0
  bottom 97px
  bottom calc(constant(safe-area-inset-bottom) + 97px)
  bottom calc(env(safe-area-inset-bottom) + 97px)
  overflow hidden
</style>
