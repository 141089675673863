<template>
  <div class="container">
    <div class="logo">
      <img class="logo-img" src="https://msyd-1304515158.cos.ap-beijing.myqcloud.com/logo.png" alt=""/>
      <div class="logo-name">{{ agencyName }}</div>
    </div>
    <router-link tag="div" to="/search" class="search">
      <div class="search-icon"></div>
      <div class="search-text"></div>
      <div class="search-btn">搜索</div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'HomeHeader',
  props: {
    logoUrl: {
      type: String,
      default: ''
    },
    agencyName: {
      type: String,
      default: ''
    },
    agencyGuid: {
      type: String,
      default: ''
    }
  },
  components: {},
  data () {
    return {
      swiperOption: {
        pagination: {
          el: '.swiper-pagination'
        }
      },
      swiper: []
    }
  }
}
</script>

<style lang='stylus' scoped>
@import "~@/assets/stylus/mixin"
.container
  position relative
  z-index 2
  display flex
  width 100%
  height 94px

  .logo
    display flex
    align-items center
    width 360px
    height 71px
    margin-top 23px
    margin-left 30px

    .logo-img
      width 60px
      height 60px
      margin-right 10px

    .logo-name
      line-height normal
      font-size: 28px
      color rgba(255, 255, 255, 1)
      text-shadow 2px 2px 4px rgba(0, 0, 0, 1)

  .search
    display flex
    width 310px
    height 52px
    margin-top 42px
    margin-left 20px
    border-radius 26px
    background rgba(255, 255, 255, 0.16)

    .search-icon
      width 27px
      height 27px
      margin 13px 8px 0 9px
      bg-image('~@/assets/img/search')
      background-size 100% 100%
      background-repeat no-repeat

    .search-text
      width 189px
      height 52px

    .search-btn
      display flex
      align-items center
      justify-content center
      width 72px
      height 42px
      line-height normal
      text-align center
      font-size 24px
      color rgba(51, 51, 51, 1)
      margin-top 5px
      border-radius 26px
      background rgba(255, 255, 255, .4)
</style>
