import Base from './Base'

export function setCUserFavorites (data) {
  return Base.post('/api/v1/c_users/setCUserFavorites', data)
}

export function getCUserFavoritesFlag (data) {
  return Base.post('/api/v1/c_users/getCUserFavoritesFlag', data)
}

export function cUserActivity (data) {
  return Base.post('/api/v1/c_users/c_user_activity', data)
}

export function getCUserActivity (data) {
  return Base.post('/api/v1/c_users/get_c_user_activity', data)
}

export function getCUserInfo (data) {
  return Base.post('/api/v1/c_users/get_c_user_info', data)
}

export function spread (data) {
  return Base.post('/api/v1/c_users/spread', data)
}

export function getCUserInfoNew (data) {
  return Base.post('/api/v1/c_users/get_c_user_info_new', data)
}

export function editinfo (data) {
  return Base.post('/api/v1/c_users/editinfo', data)
}
