<template>
  <div class="container">
    <div class="swiper-bg-block">
      <swiper class="swiper-bg" :options="swiperBGOption" ref="swiperBg" v-if="swiperList.length">
        <swiper-slide
          class="swiper-no-swiping slide-bg-item"
          v-for="(item, index) in swiperList"
          :key="index"
        >
          <div class="slide-bg-img-block" v-if="item.advert_bg_type===1">
            <img class="slide-bg-img" :src="item.advert_bg" alt=""/>
          </div>
          <div class="slide-bg-img-block" :style="{background: item.advert_bg}" v-else>
          </div>
        </swiper-slide>
      </swiper>
    </div>
    <div class="swiper-block">
      <swiper class="swiper" :options="swiperOption" ref="swiperBanner" v-if="swiperList.length">
        <swiper-slide
          class="slide"
          v-for="(item, index) in swiperList"
          :key="index"
        >
          <div class="slide-item">
            <img class="slide-item-img" :src="item.advert_media" alt=""/>
          </div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { getAudios } from '@/api/Resources'
import { Toast } from 'vant'
import { appInit } from '@/utils/init'
import { mapActions, mapGetters } from 'vuex'

const swiperTime = 5000
export default {
  name: 'HomeSwiper',
  props: {
    swiperList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    ...mapGetters([
      'userGuid',
      'agencyGuid',
      'openid'
    ])
  },
  components: {
    Swiper,
    SwiperSlide
  },
  activated () {
    if (this.swiperList.length) {
      let nowIndex = this.$refs.swiperBanner.$swiper.realIndex
      setTimeout(() => {
        if (nowIndex === this.$refs.swiperBanner.$swiper.realIndex) {
          this.$refs.swiperBanner.$swiper.animating = false
          this.$refs.swiperBanner.$swiper.slideNext()
        }
      }, swiperTime)
    }
  },
  watch: {
    swiperList () {
      this.$nextTick(() => {
        const swiperBg = this.$refs.swiperBg.$swiper
        const swiperBanner = this.$refs.swiperBanner.$swiper
        swiperBanner.controller.control = swiperBg
        swiperBg.controller.control = swiperBanner
      })
    }
  },
  data () {
    return {
      swiperBGOption: {
        loop: true,
        centeredSlides: true,
        observer: true,
        observeParents: true,
        noSwiping: true,
        effect: 'fade'
      },
      swiperOption: {
        loop: true,
        effect: 'slide',
        centeredSlides: true,
        autoplay: {
          delay: swiperTime,
          disableOnInteraction: false
        },
        observer: true,
        observeParents: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'custom',
          renderCustom: (swiper, current, total) => {
            let html = '<div class="swiper-page-block">'
            for (let i = 0; i < total; i++) {
              if (i === current - 1) {
                html += '<span class="my-bullet my-bullet-active"></span>'
              } else {
                html += '<span class="my-bullet"></span>'
              }
            }
            html += '</div>'
            return html
          },
          bulletClass: 'my-bullet',
          bulletActiveClass: 'my-bullet-active'
        },
        on: {
          click: (swiper) => {
            let currentSwiper = this.swiperList[swiper.realIndex]
            if (!currentSwiper) {
              return false
            }
            this.swiperLocation(currentSwiper)
          }
        }
      }
    }
  },
  methods: {
    swiperLocation (item) {
      if (item.advert_belong_type === 1) {
        this.$router.push('/home/book-detail/' + item.advert_belong_obj + '/电子书')
      } else if (item.advert_belong_type === 2) {
        Toast.loading({
          duration: 0,
          forbidClick: true,
          message: '加载中...'
        })
        getAudios({
          resources_guid: item.advert_belong_obj,
          c_user_guid: this.userGuid
        }).then(res => {
          if (res.code === 200) {
            if (!res.data.list.length) {
              Toast.fail('暂无音频数据')
              return false
            }
            this.selectPlay({
              list: res.data.list,
              index: res.data.current_index
            })
          } else if (res.code === 401) {
            appInit(this.agencyGuid, this.$route.path)
          } else if (res.code === 402) {
            this.$router.push({
              name: 'Login',
              params: {
                link: this.$route.path
              }
            })
          } else {
            Toast.fail(res.msg)
          }
          Toast.clear()
        })
      } else if (item.advert_belong_type === 3) {
        this.$router.push('/home/video-detail/' + item.advert_belong_obj + '/视频')
      } else if (item.advert_belong_type === 4) {
        this.$router.push('/home/activity-detail/活动/' + item.advert_belong_obj)
      } else if (item.advert_belong_type === 5) {
        this.$router.push('/books/books-detail/' + item.advert_belong_obj + '/书单')
      } else if (item.advert_belong_type === 6) {
        this.$router.push(item.advert_url_belong_obj + '?c_agency_guid=' + this.agencyGuid + '&openid=' + this.openid)
      } else if (item.advert_belong_type === 7) {
        // Toast.loading({
        //   duration: 0,
        //   forbidClick: true,
        //   message: '加载中...'
        // })
        window.location.href = item.advert_url_belong_obj + '?c_agency_guid=' + this.agencyGuid + '&openid=' + this.openid
      }
    },
    ...mapActions([
      'selectPlay'
    ])
  }
}
</script>

<style lang="stylus" scoped>
.swiper-pagination >>> .swiper-page-block
  display flex
  justify-content center
  align-items center
  width auto
  height 26px
  margin 0 auto
  border-radius 13px
  padding 0 16px
  background rgba(0, 0, 0, .2)

.swiper-pagination >>> .my-bullet
  display inline-block
  width 6px
  height 6px
  margin 0 7px
  background rgba(255, 255, 255, .3)
  border-radius 50%

.swiper-pagination >>> .my-bullet-active
  width 12px
  height 6px
  border-radius 13px
  background rgba(255, 255, 255, 1)

.container
  width 100%
  height 399px
  margin-top -94px

  .swiper-bg-block
    width 100%
    height 318px

    .swiper-bg
      width 100%
      height 100%
      overflow hidden

      .slide-bg-item
        width 100%
        height 100%

        .slide-bg-img-block
          width 100%
          height 100%

          .slide-bg-img
            width 100%
            height 100%

  .swiper-block
    width 100%
    height 276px
    margin-top -195px
    overflow hidden

    .swiper
      width 100%
      height 100%

      .slide
        width 100%
        height 276px
        //background #f00

        .slide-item
          width 690px
          height 276px
          margin 0 auto
          border-radius 10px
          overflow hidden

          .slide-item-img
            width 100%
            height 100%

      .swiper-pagination
        display flex
</style>
