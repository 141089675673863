import Base from './Base'

export function signIn (data) {
  console.log('打卡请求', data)
  return Base.post('/api/v1/sign_in/create', data)
}

export function getSignIn (data) {
  return Base.post('/api/v1/sign_in/get_sign_in', data)
}

export function lottery (data) {
  return Base.post('/api/v1/sign_in/lottery', data)
}

// 2023年中秋和国庆21天打卡活动
export function getSignIn21Day (data) {
  return Base.post('/api/v1/sign_in_new/get_sign_in', data)
}

export function signIn21Day (data) {
  return Base.post('/api/v1/sign_in_new/create', data)
}

// 抽奖
export function lottery21Day (data) {
  return Base.post('/api/v1/sign_in_new/lottery', data)
}
// 机构签到
export function setagencysign (data) {
  return Base.post('/api/v1/sign_in_new/setagency', data)
}
// 机构签到
export function useragency (data) {
  return Base.post('/api/v1/sign_in_new/useragency', data)
}
