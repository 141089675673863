import Base from './Base'

export function getResourcesPageList (data) {
  return Base.post('/api/v1/resources/getResourcesPageList', data)
}

export function getEbookInfo (data) {
  return Base.post('/api/v1/resources/getEbookInfo', data)
}

export function getResourcesDesc (data) {
  return Base.post('/api/v1/resources/getResourcesDesc', data)
}

export function getResourcesDirectoryQQ (data) {
  return Base.post('/api/v1/resources/getResourcesDirectoryQQ', data)
}

export function setCUserLog (data) {
  return Base.post('/api/v1/resources/setCUserLog', data)
}

export function getResourcesDescQQ (data) {
  return Base.post('/api/v1/resources/getResourcesDescQQ', data)
}

export function getAudios (data) {
  return Base.post('/api/v1/resources/getAudios', data)
}

export function makeaudioLong (data) {
  return Base.post('/api/v1/audiocontro/makeaudio_long', data)
}

export function makeaudioShort (data) {
  return Base.post('/api/v1/audiocontro/makeaudio_short', data)
}

export function hastag (data) {
  return Base.post('/api/v1/resources/hastag', data)
}

export function addtag (data) {
  return Base.post('/api/v1/resources/addtag', data)
}

export function deltag (data) {
  return Base.post('/api/v1/resources/deltag', data)
}
