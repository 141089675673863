import * as types from './mutation-types'
// 设置用户信息
export const setUserInfo = ({ commit }, query) => {
  return new Promise(resolve => {
    const {
      openid,
      agency_guid: agencyGuid,
      c_user_dzz: readCard,
      c_user_id: userGuid,
      wxname,
      wximg
    } = query
    console.log('query', query)
    commit(types.SET_AGENCY_GUID, agencyGuid)
    commit(types.SET_OPENID, openid)
    commit(types.SET_USER_READ_CARD, readCard)
    commit(types.SET_USER_GUID, userGuid)
    commit(types.SET_USER_NAME, wxname)
    commit(types.SET_USER_AVATAR, wximg)
    resolve()
  })
}
// 展开播放器
export const selectPlay = ({ commit }, {
  list,
  index
}) => {
  commit(types.SET_PLAY_LIST, list)
  commit(types.SET_CURRENT_INDEX, index)
  commit(types.SET_PLAYING, true)
  commit(types.SET_FULL_SCREEN, true)
}
