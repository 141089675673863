import Home from '@/views/home/Home'
import Books from '@/views/books/Books'
import Activity from '@/views/activity/Activity'
import Personal from '@/views/personal/Personal'

export const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    component: Home,
    name: 'Home',
    meta: {
      title: '书香北疆 码上悦读'
    },
    children: [
      {
        path: 'reading/:order',
        name: 'Reading',
        component: () => import('@/views/reading/Reading'),
        meta: {
          title: '看书'
        },
        children: [
          {
            path: 'book-detail/:resources_guid/:resources_name',
            name: 'BookDetail',
            component: () => import('@/views/book-detail/BookDetail'),
            children: [
              {
                path: 'ebook-detail',
                name: 'EbookDetail',
                component: () => import('@/views/ebook-detail/EbookDetail')
              },
              {
                path: 'ebook-detail-qq',
                name: 'EbookDetailQq',
                component: () => import('@/views/ebook-detail-qq/EbookDetailQq')
              }
            ]
          }
        ]
      },
      {
        path: 'book-detail/:resources_guid/:resources_name',
        name: 'HomeBookDetail',
        component: () => import('@/views/book-detail/BookDetail'),
        children: [
          {
            path: 'ebook-detail',
            name: 'HomeEbookDetail',
            component: () => import('@/views/ebook-detail/EbookDetail')
          },
          {
            path: 'ebook-detail-qq',
            name: 'HomeEbookDetailQq',
            component: () => import('@/views/ebook-detail-qq/EbookDetailQq')
          }
        ]
      },
      {
        path: 'honey',
        name: 'Honey',
        component: () => import('@/views/honey/Honey'),
        meta: {
          title: '哈尼之声'
        }
      },
      {
        path: 'listen/:audio_guid?',
        name: 'Listen',
        component: () => import('@/views/listen/Listen'),
        meta: {
          title: '听书'
        }
      },
      {
        path: 'video/:order',
        name: 'Video',
        component: () => import('@/views/video/Video'),
        meta: {
          title: '视频'
        },
        children: [
          {
            path: 'video-detail/:guid/:name',
            name: 'VideoDetail',
            component: () => import('@/views/video-detail/VideoDetail')
          }
        ]
      },
      {
        path: 'activity-detail/:name/:guid',
        name: 'HomeActivityDetail',
        component: () => import('@/views/activity-detail/ActivityDetail')
      },
      {
        path: 'video-detail/:guid/:name',
        name: 'HomeVideoDetail',
        component: () => import('@/views/video-detail/VideoDetail')
      },
      {
        path: 'act-video-detail/:guid/:name',
        name: 'ActHomeVideoDetail',
        component: () => import('@/views/act-video-detail/VideoDetail')
      },
      {
        path: 'article-list',
        name: 'ArticleList',
        component: () => import('@/views/article-list/ArticleList'),
        meta: { title: '读者服务' },
        children: [
          {
            path: 'article/:guid',
            name: 'Article',
            component: () => import('@/views/article/Article')
          }
        ]
      },
      {
        path: 'article/:guid',
        name: 'HomeArticle',
        component: () => import('@/views/article/Article')
      },
      {
        path: 'recommend-resources/:recommend_guid/:type',
        name: 'RecommendResources',
        component: () => import('@/views/recommend-resources/RecommendResources'),
        children: [
          {
            path: 'book-detail/:resources_guid/:resources_name',
            name: 'RecommendResourcesBookDetail',
            component: () => import('@/views/book-detail/BookDetail'),
            children: [
              {
                path: 'ebook-detail',
                name: 'RecommendResourcesEbookDetail',
                component: () => import('@/views/ebook-detail/EbookDetail')
              },
              {
                path: 'ebook-detail-qq',
                name: 'RecommendResourcesEbookDetailQq',
                component: () => import('@/views/ebook-detail-qq/EbookDetailQq')
              }
            ]
          },
          {
            path: 'video-detail/:guid/:name',
            name: 'RecommendResourcesVideoDetail',
            component: () => import('@/views/video-detail/VideoDetail')
          }
        ]
      },
      { // 答题活动
        path: 'answer-home',
        name: 'AnswerHome',
        component: () => import('@/views/answer/AnswerHome'),
        children: [
          {
            path: 'answer-question',
            name: 'AnswerQuestion',
            component: () => import('@/views/answer/AnswerQuestion')
          },
          {
            path: 'answer-rank',
            name: 'AnswerRank',
            component: () => import('@/views/answer/AnswerRank')
          }
        ]
      },
      { // 答题活动新
        path: 'answer-home-new',
        name: 'AnswerHomeNew',
        component: () => import('@/views/answernew/AnswerHome'),
        children: [
          {
            path: 'answer-question',
            name: 'AnswerQuestion',
            component: () => import('@/views/answernew/AnswerQuestion')
          },
          {
            path: 'answer-rank',
            name: 'AnswerRank',
            component: () => import('@/views/answernew/AnswerRank')
          }
        ]
      },
      {
        path: 'dbf-rank',
        name: 'DbfRank',
        component: () => import('@/views/festival/d-b-f/DbfRank')
      }
    ]
  },
  {
    path: '/books',
    name: 'Books',
    component: Books,
    meta: {
      title: '书单'
    },
    children: [
      {
        path: 'books-detail/:resources_class_guid/:resources_class_name',
        name: 'BooksDetail',
        component: () => import('@/views/books-detail/BooksDetail'),
        meta: {
          title: '书单'
        },
        children: [
          {
            path: 'book-detail/:resources_guid/:resources_name',
            name: 'BooksDetailBookDetail',
            component: () => import('@/views/book-detail/BookDetail'),
            children: [
              {
                path: 'ebook-detail',
                name: 'BooksDetailEbookDetail',
                component: () => import('@/views/ebook-detail/EbookDetail')
              },
              {
                path: 'ebook-detail-qq',
                name: 'BooksDetailEbookDetailQq',
                component: () => import('@/views/ebook-detail-qq/EbookDetailQq')
              }
            ]
          },
          {
            path: 'video-detail/:guid/:name',
            name: 'BooksDetailVideoDetail',
            component: () => import('@/views/video-detail/VideoDetail')
          }
        ]
      }
    ]
  },
  {
    path: '/activity',
    name: 'Activity',
    component: Activity,
    meta: {
      title: '活动'
    },
    children: [
      {
        path: 'activity-detail/:name/:guid',
        name: 'ActivityDetail',
        component: () => import('@/views/activity-detail/ActivityDetail')
      }
    ]
  },
  {
    path: '/personal',
    name: 'Personal',
    component: Personal,
    meta: {
      title: '书香北疆 码上悦读'
    },
    children: [

      {
        // 签到打开
        path: 'sign-in',
        name: 'SignIn',
        component: () => import('@/views/sign-in/SignIn'),
        meta: { title: '签到打卡' },
        children: [
          {
            // 抽奖
            path: 'lottery',
            name: 'Lottery',
            component: () => import('@/views/personal/Lottery')
          },
          {
            path: 'video-detail/:guid/:name',
            name: 'SignInVideoDetail',
            component: () => import('@/views/video-detail/VideoDetail')
          }
        ]
      },
      {
        path: 'question',
        name: 'Question',
        component: () => import('@/views/question/Question'),
        meta: { title: '咨询/反馈' }
      },
      {
        path: 'msg',
        name: 'Msg',
        component: () => import('@/views/personal/Msg'),
        meta: { title: '通知' }
      },
      {
        path: 'my-favorites',
        name: 'MyFavorites',
        component: () => import('@/views/my-favorites/MyFavorites'),
        meta: {
          title: '我的收藏'
        }
      },

      {
        // 21天签到打卡
        path: 'sign-in21day',
        name: 'SignIn21Day',
        component: () => import('@/views/sign-in21day/SignIn'),
        meta: { title: '签到打卡' },
        children: [
          {
            // 抽奖
            path: 'lottery',
            name: 'Lottery',
            component: () => import('@/views/personal/Lottery')
          }
        ]
      },
      {
        // 个人分享推广
        path: 'my-promotion',
        name: 'MyPromotion',
        component: () => import('@/views/my-promotion/promotion'),
        meta: { title: '分享推广' }
      },
      {
        // 个人分享推广记录
        path: 'my-promotionlog',
        name: 'MyPromotionLog',
        component: () => import('@/views/my-promotion/logs'),
        meta: { title: '推广记录' }
      }

    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login/Login'),
    children: [
      {
        path: 'user-agreement',
        name: 'UserAgreement',
        component: () => import('@/views/user-agreement/UserAgreement'),
        meta: {
          title: '用户协议'
        }
      },
      {
        path: 'privacy-agreement',
        name: 'PrivacyAgreement',
        component: () => import('@/views/privacy-agreement/PrivacyAgreement'),
        meta: {
          title: '隐私协议'
        }
      }
    ]
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('@/views/search/Search'),
    meta: {
      title: '搜索'
    }
  },
  {
    path: '/search-res',
    name: 'SearchRes',
    component: () => import('@/views/search-res/SearchRes'),
    meta: {
      title: '搜索'
    }
  },
  {
    path: '/location/:url',
    name: 'Location',
    component: () => import('@/views/location/Location')
  },
  {
    path: '/empty/:msg',
    name: 'Empty',
    component: () => import('@/views/empty/Empty')
  },
  { // 中秋活动
    path: '/activity-eva',
    name: 'ActivityEva',
    component: () => import('@/views/activity-eva/ActivityEva'),
    meta: {
      title: '云端漫读计划'
    }
  },
  { // 中秋活动
    path: '/books-eva',
    name: 'BooksEva',
    component: () => import('@/views/activity-eva/BooksEva'),
    meta: {
      title: '云端漫读计划'
    }
  },
  { // 中秋活动-点赞
    path: '/rank-eva',
    name: 'RankEva',
    component: () => import('@/views/activity-eva/RankEva'),
    meta: {
      title: '云端漫读计划'
    }
  },
  {
    path: '/vieanswerquestion',
    name: 'answerQuestion',
    component: () => import('@/views/vieAnswer/AnswerQuestion'),
    meta: {
      title: '元宵线下竞答活动'
    }
  },
  { // 元宵线下竞答活动
    path: '/vieanswer',
    name: 'VieAnswer',
    component: () => import('@/views/vieAnswer/index'),
    meta: {
      title: '元宵线下竞答活动'
    }
  },
  {
    path: '/vieanswerrank',
    name: 'answerRank',
    component: () => import('@/views/vieAnswer/AnswerRank'),
    meta: {
      title: '元宵线下竞答活动'
    }
  },
  {
    path: '/vieanswerset',
    name: 'VieanswerSet',
    component: () => import('@/views/vieAnswer/setting'),
    meta: {
      title: '元宵线下竞答活动'
    }
  },
  { // 投票活动
    path: '/toupiao',
    name: 'Toupiao',
    component: () => import('@/views/toupiao/index'),
    meta: {
      title: '“阅.见西部第一季” “诗歌里的家乡”人气作品投票'
    }
  }
  // {
  //   // 个人分享推广记录
  //   path: '/my-promotionlog',
  //   name: 'MyPromotionLog',
  //   component: () => import('@/views/my-promotion/logs'),
  //   meta: { title: '推广记录' }
  // }

]
