<template>
  <div>
    <overlay
      :show="showOverlay"
    >
      <div class="wrapper">
        <div class="activity-block">
          <div class="activity-img"></div>
          <div class="close" @click="showOverlay = false"></div>
        </div>
      </div>
    </overlay>
  </div>
</template>

<script>
import { Overlay } from 'vant'
import { initActivity } from '@/api/Activity'
import { mapGetters } from 'vuex'

export default {
  name: 'Fu',
  components: {
    Overlay
  },
  computed: {
    ...mapGetters(['agencyGuid', 'openid'])
  },
  data () {
    return {
      showOverlay: false
    }
  },
  watch: {
    $route () {
      this.showOverlay = false
      if (this.agencyGuid === '20ceaf92-a3da-325c-5cc5-3617d3f68b9d') {
        initActivity({
          agency_guid: this.agencyGuid,
          openid: this.openid
        }).then(res => {
          if (res.code === 200) {
            this.showOverlay = res.data
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.van-overlay
  z-index 100000
.wrapper
  display flex
  justify-content center
  align-items center
  width 750px
  height 100%

  .activity-block
    display flex
    flex-wrap wrap
    justify-content center
    width 530px
    height 700px

    .activity-img
      width 530px
      height 530px
      bg-image('~@/assets/img/activity/activity')
      background-size 100% 100%
      background-repeat no-repeat

    .close
      width 60px
      height 60px
      bg-image('~@/assets/img/activity/activity-close')
      background-size 100% 100%
      background-repeat no-repeat
</style>
