export const SET_AGENCY_GUID = 'SET_AGENCY_GUID'
export const SET_USER_GUID = 'SET_USER_GUID'
export const SET_USER_NAME = 'SET_USER_NAME'
export const SET_OPENID = 'SET_OPENID'
export const SET_USER_READ_CARD = 'SET_USER_READ_CARD'
export const SET_USER_AVATAR = 'SET_USER_AVATAR'
export const SET_PLAYING = 'SET_PLAYING'
export const SET_FULL_SCREEN = 'SET_FULL_SCREEN'
export const SET_PLAY_LIST = 'SET_PLAY_LIST'
export const SET_CURRENT_INDEX = 'SET_CURRENT_INDEX'
export const SET_SEARCH_LIST = 'SET_SEARCH_LIST'
export const SET_USER_CITY_NAME = 'SET_USER_CITY_NAME'
export const SET_ACTIVITY = 'SET_ACTIVITY'
