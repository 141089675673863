<template>
  <div class="wrapper">
    <div class="activity">
      <router-link
        tag="div"
        :to="'/activity/activity-detail/'+item.activity_name+'/'+item.activity_guid"
        class="activity-info"
        v-for="(item, index) in activityList"
        :key="index"
      >
        <div class="info-pic">
          <img class="pic" :src="item.activity_cover"/>
          <div class="is-over" v-if="item.activity_is_over===1">已结束</div>
          <div class="on-line" v-else>{{ item.activity_type }}</div>
        </div>
        <div class="info-text">
          <div class="info-title">{{ item.activity_name }}</div>
          <div class="info-time">
            <div class="time-icon"></div>
            <div class="time-text">时间：{{ item.activity_time }}</div>
          </div>
          <div class="info-addr">
            <div class="addr-icon"></div>
            <div class="addr-text">地点：{{ item.activity_addr }}</div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ActivityList',
  props: {
    activityList: {
      type: Array,
      default: null
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/mixin"
.wrapper
  width 750px
  margin-top 35px

  .activity
    width 690px
    margin 0 auto
    padding-top 27px
    box-shadow 0px 5px 10px 0px rgba(0, 0, 0, 0.02)
    border-radius 10px
    background #fff
    overflow hidden

    .activity-info
      display flex
      width 652px
      height 170px
      padding-bottom 30px
      margin 0 auto 30px auto
      border-bottom 1px solid rgba(215, 215, 215, .5)

      &:last-child
        border-bottom none
        margin-bottom 0

      .info-pic
        position relative
        width 220px
        height 170px
        border-radius 10px
        overflow hidden

        .pic
          width 100%
          height 100%

        .on-line
          position absolute
          bottom 0
          right 0
          width 110px
          height 46px
          border-radius: 10px 0 10px 0
          line-height 46px
          text-align center
          font-size 22px
          color rgba(255, 255, 255, 1)
          background rgba(0, 0, 0, .4)

        .is-over
          position absolute
          left 55px
          top 30px
          width 110px
          height 110px
          line-height 110px
          text-align center
          font-size 24px
          color rgba(102, 102, 102, 1)
          background rgba(199, 199, 199, 1)
          border-radius 50%

      .info-text
        width 432px
        height 170px

        .info-title
          width 404px
          height 60px
          line-height 34px
          padding 6px 0 0 28px
          font-size 26px
          color rgba(51, 51, 51, 1)
          no-wrap2(2)

        .info-time
          display flex
          align-items center
          width 432px
          height 22px
          line-height normal
          margin-top 41px

          .time-icon
            width 20px
            height 20px
            margin-left 28px
            bg-image('~@/assets/img/time')
            background-size 100% 100%
            background-repeat no-repeat

          .time-text
            width 385px
            margin-left 10px
            font-size 22px
            color rgba(153, 153, 153, 1)
            no-wrap()

        .info-addr
          display flex
          align-items center
          width 432px
          height 22px
          line-height normal
          margin-top 14px

          .addr-icon
            width 20px
            height 20px
            margin-left 28px
            bg-image('~@/assets/img/addr')
            background-size 100% 100%
            background-repeat no-repeat

          .addr-text
            width 385px
            margin-left 10px
            font-size 22px
            color rgba(153, 153, 153, 1)
            no-wrap()
</style>
