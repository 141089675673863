<template>
  <div class="question-popup" v-show="show">
    <div class="question-popup-body">
      <div class="question-popup-title">线上知识竞答活动</div>
      <div class="question-popup-content">恭喜你获得1次答题机会</div>
      <div @click="link" class="question-popup-btn">点我答题
        <div class="ico"></div>
      </div>
    </div>
    <div class="question-popup-close" @click="show = false"></div>
  </div>
</template>

<script>
import router from '@/router'

export default {
  name: 'QuestionPopup',
  data () {
    return {
      show: false,
      activity_guid: ''
    }
  },
  created () {
  },
  methods: {
    showPopup () {
      this.show = true
    },
    link () {
      if (localStorage.getItem('activity')) {
        let activity = localStorage.getItem('activity') ? JSON.parse(localStorage.getItem('activity')) : {}
        router.push({ path: '/home/answer-home', query: { activity_guid: activity.activity_guid } })
      } else {
        router.push({ path: '/home/answer-home', query: { activity_guid: this.activity_guid } })
      }
      this.clear()
    },
    clear () {
      this.show = false
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin.styl"
.question-popup
  position absolute
  left 0
  right 0
  top 0
  bottom 0
  z-index 10000

  .question-popup-body
    display table
    width 480px
    height 598px
    margin 200px auto 50px auto
    bg-image2('msyq-question/popup20230412')
    background-size 100% 100%
    background-repeat no-repeat

    .question-popup-title
      display flex
      align-items center
      justify-content center
      width 100%
      height 54px
      margin 80px auto 0 auto
      font-size 48px
      color rgba(255, 255, 255, 1)
      font-weight bold

    .question-popup-content
      width 100%
      line-height 44px
      text-align center
      margin 20px auto 0 auto
      font-size 32px
      color rgba(255, 255, 255, 1)

    .question-popup-btn
      display flex
      justify-content center
      align-items center
      box-sizing border-box
      width 348px
      height 70px
      margin 280px auto 0 auto
      font-size 34px
      color rgba(255, 255, 255, 1)
      background rgba(255, 95, 104, 1)
      border-radius 35px
      box-shadow: 0 7px 0 0 #DD2C36

  .question-popup-close
    width 56px
    height 56px
    margin 0 auto
    bg-image2('msyq-question/close')
    background-size 100% 100%
    background-repeat no-repeat
</style>
