<template>
  <div @touchmove.prevent>
    <scroll ref="scroll" class="scroll" :data="data">
      <div>
        <home-header
          :logo-url="agencyInfo.agency_logo"
          :agency-name="agencyInfo.agency_name"
          :agency-guid="agencyGuid"
        ></home-header>
        <home-swiper :swiper-list="swiperList"></home-swiper>
        <home-bulletin></home-bulletin>
        <home-icon-nav></home-icon-nav>
        <icon-tab></icon-tab>
        <home-recommends @refresh="refresh"></home-recommends>
        <div class="home-bottom">
          <divider class="divider">已加载全部内容</divider>
        </div>
      </div>
    </scroll>
    <overlay :show="guideFlag" @click="guideFlag = false">
      <guide></guide>
    </overlay>
    <router-view></router-view>
  </div>
</template>
<script>
import Scroll from '@/components/scroll/scroll'
import HomeHeader from './components/Header'
import HomeSwiper from './components/Swiper'
import HomeBulletin from './components/Bulletin'
import HomeIconNav from './components/IconNav'
import HomeRecommends from './components/Recommends'
import { Divider, Toast, Overlay } from 'vant'
import { mapGetters } from 'vuex'
import { getAgencyInfo } from '@/api/Agency'
import { getAdverts } from '@/api/Advert'
import Guide from '@/views/home/components/Guide'
import IconTab from '@/views/home/components/IconTab'

export default {
  name: 'Home',
  components: {
    IconTab,
    Guide,
    Scroll,
    HomeHeader,
    HomeSwiper,
    HomeBulletin,
    HomeIconNav,
    HomeRecommends,
    Divider,
    Overlay
  },
  computed: {
    ...mapGetters([
      'agencyGuid'
    ])
  },
  activated () {
    this.refresh()
  },
  mounted () {
    this._homeInit()
  },
  data () {
    return {
      agencyInfo: {
        agency_logo: '',
        agency_name: ''
      }, // 机构信息
      swiperList: [], // 轮播列表
      data: [],
      guideFlag: false // 引导收藏
    }
  },
  methods: {
    refresh () {
      setTimeout(() => {
        this.$refs.scroll.refresh()
      }, 100)
    },
    async _homeInit () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      await Promise.all([
        getAgencyInfo({
          agency_guid: this.agencyGuid
        }).then(res => {
          if (res.code === 200) {
            this.agencyInfo = res.data
          } else {
            this.$router.push(`/empty/${res.msg}`)
          }
        }),
        getAdverts({
          advert_type: 1,
          advert_agency_guid: this.agencyGuid
        }).then(res => {
          if (res.code === 200) {
            this.swiperList = res.data
          }
        })
      ])
      this.refresh()
      Toast.clear()
    }
  }
}
</script>
<style lang='stylus' scoped>
.scroll
  position absolute
  z-index 0
  top 0
  left 0
  right 0
  bottom 97px
  bottom calc(constant(safe-area-inset-bottom) + 97px)
  bottom calc(env(safe-area-inset-bottom) + 97px)
  overflow hidden

  .advert
    display block
    width 690px
    margin 20px auto 0 auto

  .home-bottom
    wdith 750px
    height 70px
    padding-top 30px

    .divider
      width 300px
      height 22px
      font-size 22px
      color rgba(153, 153, 153, 1)
      margin 0 auto
      border-color rgba(153, 153, 153, 1)
</style>
