export function getDate () {
  let dateObj = new Date()
  let year = dateObj.getFullYear()
  let month = dateObj.getMonth() + 1
  let strDate = dateObj.getDate()

  if (month >= 1 && month <= 9) {
    month = '0' + month
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = '0' + strDate
  }
  let date = `${year}-${month}-${strDate}`
  return date
}
export function getDate2 () {
  let dateObj = new Date()
  let year = dateObj.getFullYear()
  let month = dateObj.getMonth() + 1
  let strDate = dateObj.getDate()

  if (month >= 1 && month <= 9) {
    month = '0' + month
  }
  strDate = strDate + 1
  if (strDate >= 0 && strDate <= 9) {
    strDate = '0' + strDate
  }
  let date = `${year}-${month}-${strDate}`
  return date
}
