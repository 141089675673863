import Base from './Base'

export function getBooksList (data) {
  return Base.post('/api/v1/books/get_books_list', data)
}

export function getBooksDetail (data) {
  return Base.post('/api/v1/books/get_books_detail', data)
}

export function getBooksResources (data) {
  return Base.post('/api/v1/books/get_books_resources', data)
}
