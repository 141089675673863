<template>
  <div class="wrapper">
    <div class="ebook-list">
      <router-link
        :to="'/books/books-detail/' + item.resources_class_guid + '/' + item.resources_class_name"
        tag="div"
        class="ebook-item"
        v-for="(item, index) in bookList"
        :key="index"
      >
        <div class="ebook-cover">
          <img class="ebook-cover-img" :src="item.cover"/>
        </div>
        <div class="ebook-info">
          <div class="ebook-info-title">
            {{ item.resources_class_name }}
          </div>
          <div class="ebook-info-desc">
            {{ item.resources_class_desc }}
          </div>
          <div class="ebook-info-author">
            <!--            <div class="ebook-info-author-icon"></div>-->
            <!--            <div class="ebook-info-author-author">Yuval Noah Harari（作者）</div>-->
            <div class="ebook-info-author-label">书单</div>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BooksList',
  props: {
    bookList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {}
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/mixin"
.wrapper
  display flex
  flex-wrap wrap
  justify-content center
  align-items center
  width 100%
  margin-top 36px

  .ebook-list
    width 690px

    .ebook-item
      display flex
      align-items center
      width 650px
      padding 0 20px
      height 270px
      border-radius 10px
      background rgba(255, 255, 255, 1)
      box-shadow 0px 5px 10px 0px rgba(0, 0, 0, 0.02)
      margin-bottom 10px

      .ebook-cover
        width 166px
        height 228px
        border-radius 10px
        overflow hidden

        .ebook-cover-img
          width 100%
          height 100%

      .ebook-info
        display flex
        flex-wrap wrap
        align-items center
        width 450px
        height 228px
        margin-left 25px

        .ebook-info-title
          width 450px
          line-height normal
          font-size 26px
          color rgba(51, 51, 51, 1)
          margin-top 10px
          no-wrap()

        .ebook-info-desc
          width 450px
          line-height normal
          font-size 24px
          color rgba(153, 153, 153, 1)
          margin-top 10px
          no-wrap2(2)

        .ebook-info-author
          display flex
          align-items center
          width 450px
          height 32px

          .ebook-info-author-icon
            width 16px
            height 16px
            bg-image('~@/assets/img/reading/author')
            background-size 100% 100%
            background-repeat no-repeat

          .ebook-info-author-author
            width 300px
            line-height normal
            font-size 22px
            color rgba(153, 153, 153, 1)
            margin-left 10px
            no-wrap()

          .ebook-info-author-label
            margin-left auto
            padding 0 10px
            line-height normal
            font-size 20px
            color rgba(245, 151, 1, 1)
            border-radius 6px
            border 1px solid rgba(245, 151, 1, 1)
</style>
