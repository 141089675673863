<template>
  <div class="icon-nav">
    <div class="icon-list">
      <router-link tag="div" to="/books" class="icon-item">
        <div class="icon books"></div>
        <div class="icon-text">书单</div>
      </router-link>
      <router-link tag="div" to="/home/reading/create_time" class="icon-item">
        <div class="icon reading"></div>
        <div class="icon-text">看书</div>
      </router-link>
      <router-link tag="div" to="/home/honey" class="icon-item">
        <div class="icon hnzs"></div>
        <div class="icon-text">哈尼之声</div>
      </router-link>
      <router-link tag="div" to="/home/listen" class="icon-item">
        <div class="icon listen"></div>
        <div class="icon-text">听书</div>
      </router-link>
      <router-link tag="div" to="/home/video/create_time" class="icon-item">
        <div class="icon video"></div>
        <div class="icon-text">视频</div>
      </router-link>
    </div>
    <div class="icon-nav-bg"></div>
  </div>
</template>

<script>
export default {
  name: 'HomeIconNav'
}
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/mixin"
.icon-nav
  width 100%
  height 187px

  .icon-list
    display flex
    width 100%
    height 150px
    margin-top 31px

    .icon-item
      width 140px
      height 119px
      margin 0 5px

      .icon
        width 82px
        height 89px
        margin 0 auto

        &.books
          bg-image('~@/assets/img/books')
          background-size 100% 100%
          background-repeat no-repeat

        &.reading
          bg-image('~@/assets/img/reading')
          background-size 100% 100%
          background-repeat no-repeat

        &.hnzs
          bg-image('~@/assets/img/hnzs')
          background-size 100% 100%
          background-repeat no-repeat

        &.listen
          bg-image('~@/assets/img/listen')
          background-size 100% 100%
          background-repeat no-repeat

        &.video
          bg-image('~@/assets/img/video')
          background-size 100% 100%
          background-repeat no-repeat

      .icon-text
        width: 140px
        height 22px
        line-height 22px
        text-align center
        font-size 22px
        margin-top 8px

  .icon-nav-bg
    width 100%
    height 109px
    margin-top -72px
    bg-image('~@/assets/img/shelf')
    background-size 100% 100%
    background-repeat no-repeat
</style>
