import * as types from './mutation-types'

const mutations = {
  [types.SET_AGENCY_GUID] (state, str) {
    state.agencyGuid = str
    localStorage.agencyGuid = str
  },
  [types.SET_USER_GUID] (state, str) {
    state.userGuid = str
    localStorage.userGuid = str
  },
  [types.SET_USER_NAME] (state, str) {
    state.userName = str
    localStorage.userName = str
  },
  [types.SET_OPENID] (state, str) {
    state.openid = str
    localStorage.openid = str
  },
  [types.SET_USER_READ_CARD] (state, str) {
    state.userReadCard = str
    localStorage.setItem('userReadCard', str)
  },
  [types.SET_USER_AVATAR] (state, str) {
    state.userAvatar = str
    localStorage.userAvatar = str
  },
  [types.SET_PLAYING] (state, flag) {
    state.playing = flag
  },
  [types.SET_FULL_SCREEN] (state, flag) {
    state.fullScreen = flag
  },
  [types.SET_PLAY_LIST] (state, arr) {
    state.playList = arr
  },
  [types.SET_CURRENT_INDEX] (state, number) {
    state.currentIndex = number
  },
  [types.SET_SEARCH_LIST] (state, arr) {
    localStorage.searchList = JSON.stringify(arr)
    state.searchList = arr
  },
  [types.SET_USER_CITY_NAME] (state, name) {
    state.userCityName = name
  },
  [types.SET_ACTIVITY] (state, str) {
    localStorage.activity = JSON.stringify(str)
    state.activity = str
  }
}

export default mutations
