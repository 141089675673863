import Base from './Base'

export function getActivityPageList (data) {
  return Base.post('/api/v1/activities/getActivityPageList', data)
}

export function getActivitiesDetail (data) {
  return Base.post('/api/v1/activities/getActivitiesDetail', data)
}

export function initActivity (data) {
  return Base.post('/api/v1/activities/init_activity', data)
}

export function openninglist (data) {
  return Base.post('/api/v1/activities/opening_list', data)
}

export function getCurrentConnect (data) {
  return Base.post('/api/v1/activities/getCurrentConnect', data)
}

export function setactivity (data) {
  return Base.post('/api/v1/activities/setactivity', data)
}

export function getagencys (data) {
  return Base.post('/api/v1/activities/getagencys', data)
}

export function activityspread (data) {
  return Base.post('/api/v1/activities/activity_spread', data)
}

export function hylogin (data) {
  return Base.post('/api/v1/chaoxingbaoku/login', data)
}
