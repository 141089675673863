<template>
  <div>
    <div class="nav">
      <router-link to="/home" tag="div" class="nav-item">
        <div class="nav-icon icon-home"></div>
        <div class="nav-desc">主页</div>
      </router-link>
      <router-link to="/books" tag="div" class="nav-item">
        <div class="nav-icon icon-books"></div>
        <div class="nav-desc">书单</div>
      </router-link>
      <div class="audio-block" @click="selectItem">
        <div class="audio">
          <div class="player">
            <img class="pic" v-show="playList.length" :src="currentSong.media_cover"/>
            <div class="play"></div>
          </div>
        </div>
        <div class="mask"></div>
      </div>
      <router-link to="/activity" tag="div" class="nav-item">
        <div class="nav-icon icon-activity"></div>
        <div class="nav-desc">活动</div>
      </router-link>
      <router-link to="/personal" tag="div" class="nav-item">
        <div class="nav-icon icon-personal">
          <div class="badge" v-show="userMsgCount>0"></div>
        </div>
        <div class="nav-desc">我的</div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Index',
  computed: {
    ...mapGetters([
      'currentSong',
      'playList',
      'userMsgCount'
    ])
  },
  methods: {
    selectItem () {
      if (this.playList.length) {
        this.setFullScreen(true)
      } else {
        this.$router.push('/home/listen')
      }
    },
    ...mapMutations({
      setFullScreen: 'SET_FULL_SCREEN'
    })
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/mixin"
.nav
  position fixed
  bottom 0
  width 750px
  height 97px
  display flex
  justify-content center
  align-items center
  border-top 1px solid #EEEEEE
  padding-bottom constant(safe-area-inset-bottom)
  padding-bottom env(safe-area-inset-bottom)
  background #FFFFFF

  .nav-item
    width 150px
    height 97px
    text-align center
    font-size 20px
    color rgba(153, 153, 153, 1)

    .nav-icon
      position relative
      width 38px
      height 34px
      margin 20px auto 0 auto

      .badge
        position relative
        top 0
        left 34px
        width 12px
        height 12px
        border-radius 50%
        background #e0423e

    .nav-desc
      margin-top 7px

    .icon-home
      bg-image('~@/assets/img/Tab_nor_home')
      background-size 100% 100%
      background-repeat no-repeat

    .icon-books
      bg-image('~@/assets/img/Tab_nor_books')
      background-size 100% 100%
      background-repeat no-repeat

    .icon-activity
      bg-image('~@/assets/img/Tab_nor_activity')
      background-size 100% 100%
      background-repeat no-repeat

    .icon-personal
      bg-image('~@/assets/img/Tab_nor_personal')
      background-size 100% 100%
      background-repeat no-repeat

    &.router-link-active
      color rgba(179, 53, 58, 1)

      .icon-home
        bg-image('~@/assets/img/Tab_sel_home')

      .icon-books
        bg-image('~@/assets/img/Tab_sel_books')

      .icon-activity
        bg-image('~@/assets/img/Tab_sel_activity')

      .icon-personal
        bg-image('~@/assets/img/Tab_sel_personal')

  .audio-block
    width 150px
    height 127px
    margin-bottom 30px

    .audio
      width 108px
      height 108px
      margin 0 auto
      border-radius 50%
      border 1px solid #EEE
      background #fff

      .player
        position relative
        top 5px
        width 98px
        height 98px
        border-radius 50%
        margin 0 auto
        bg-image('~@/assets/img/player-bg')
        background-size 100% 100%
        background-repeat no-repeat
        overflow hidden

        .pic
          width 100%
          height 100%

        .play
          width 22px
          height 26px
          position absolute
          top 36px
          left 41px
          bg-image('~@/assets/img/play')
          background-size 100% 100%
          background-repeat no-repeat

    .mask
      width 150px
      height 97px
      margin-top -82px
      background #fff
</style>
