import Axios from 'axios'
import Aes from '@/utils/aes'
import { uuid } from '@/utils/uuid'

export default {
  post (url, data) {
    let header = {
      'app-type': 'web',
      key: '1d154c9e5e4781f8d593fabe2cfabd0c',
      'nonce-str': uuid(),
      time: new Date().getTime(),
      version: '1.0.0'
    }
    let sign = Aes.encrypt(new URLSearchParams(header).toString())
    header.sign = sign
    let sendData = Aes.encrypt(JSON.stringify(data))
    return Axios({
      method: 'post',
      url: url,
      data: sendData,
      headers: header
    }).then(response => {
      let responseData = Aes.decrypt(response.data)
      return responseData
    })
  }
}
