<template>
  <div class="wrapper">
    <img class="share-close"
         @click="shareClose"
         v-show="showCanvas"
         src="@/assets/img/book-detail/share-close@3x.png"/>
    <div class="share"
         ref="share">
      <img class="share-bg"
           src="@/assets/img/book-detail/share-bg@3x.png"/>
      <div class="share-info">
        <div class="cover-info" :style="{background: bg}">
          <div class="date">
            <div class="info">{{ year }}</div>
            <div class="info">{{ month }}{{ date }}</div>
          </div>
          <div class="cover-pic">
            <img class="cover-bg"
                 src="@/assets/img/book-detail/cover-bg@3x.png"/>
            <img class="pic"
                 :src="picUrl"
                 crossorigin="anonymous"/>
          </div>
          <div class="mask">
            <div class="name">{{ name }}</div>
            <div class="author">{{ author }}</div>
          </div>
        </div>
        <div class="user-info">
          <img class="pointer" src="@/assets/img/book-detail/pointer@3x.png"/>
          <div class="user">
            <div class="name">我是 {{ userName }}</div>
            <div class="desc">邀请您与我一起畅听此书</div>
          </div>
          <div class="qr-code">
            <img class="qr-code-bg"
                 src="@/assets/img/book-detail/qrcode@3x.png"/>
            <img class="qr-code-pic"
                 :src="qrCode">
          </div>
        </div>
      </div>
    </div>
    <div class="share-canvas" v-show="showCanvas">
      <img :src="canvasImageUrl" class="share-canvas-img"/>
    </div>
    <div class="share-btn" v-show="showCanvas">
      <div class="btn-msg">长按图片保存</div>
    </div>
  </div>
</template>

<script>
import html2canvas from 'html2canvas'
import { getQrCode } from '@/api/Common'
import { LOCAL } from '@/utils/const'
import { Toast } from 'vant'

export default {
  name: 'ActivityShare',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    picUrl: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    author: {
      type: String,
      default: ''
    },
    userName: {
      type: String,
      default: ''
    },
    userGuid: {
      type: String,
      default: ''
    },
    agencyGuid: {
      type: String,
      default: ''
    }
  },
  computed: {
    bg () {
      return this.bgColor[Math.round(Math.random() * (this.bgColor.length - 1))]
    }
  },
  mounted () {
    let nowDate = new Date()
    this.year = nowDate.getFullYear()
    this.month = nowDate.getMonth() + 1
    this.date = nowDate.getDate()
    if (this.month >= 1 && this.month <= 9) {
      this.month = '0' + this.month
    }
    if (this.date >= 0 && this.date <= 9) {
      this.date = '0' + this.date
    }
  },
  data () {
    return {
      canvasImageUrl: '',
      showCanvas: false,
      bgColor: [
        'rgb(98, 181, 180)',
        'rgb(241, 209, 170)'
      ],
      qrCode: '',
      year: '',
      month: '',
      date: ''
    }
  },
  methods: {
    htmlToCanvas () {
      html2canvas(this.$refs.share, {
        backgroundColor: null,
        useCORS: true,
        scale: 4
      }).then((canvas) => {
        let imageUrl = canvas.toDataURL('image/png', 1.0)
        this.canvasImageUrl = imageUrl
        this.showCanvas = true
      })
    },
    shareClose () {
      this.$emit('shareClose')
    }
  },
  watch: {
    async show () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      await getQrCode({
        qr_str: `${LOCAL}/#${this.$route.path}?agency_guid=${this.agencyGuid}&guide=${this.userGuid}`
      }).then(res => {
        if (res.code === 200) {
          this.qrCode = res.data
          Toast.clear()
        } else {
          Toast.fail(res.data)
        }
      })
      this.$nextTick(() => {
        this.htmlToCanvas()
      })
    }
  }
}
</script>

<style scoped lang="stylus">
.wrapper
  display flex
  justify-content center
  flex-wrap wrap
  align-items center
  width 750px

  .share-close
    position absolute
    z-index 100
    top 50px
    right 100px
    width 32px
    height 32px

  .share
    position fixed
    top -1000px
    width 610px
    height 930px
    margin-top 20px

    .share-bg
      position absolute
      top 0
      left 0
      width 610px
      height 930px

    .share-info
      position absolute
      z-index 10
      top 0
      left 0
      width 610px
      height 930px

      .cover-info
        position relative
        display table
        width 590px
        height 670px
        margin 10px auto

        .date
          display flex
          justify-content center
          align-items center
          flex-wrap wrap
          width 80px
          padding 10px
          border-radius 10px
          margin 24px auto 0 auto
          border 1px solid rgba(255, 255, 255, 1)

          .info
            font-size 24px
            color rgba(255, 255, 255, 1)
        .cover-pic
          position absolute
          z-index 10
          top 116px
          right 0
          bottom 0
          left 0
          margin 0 auto
          width 308px
          height 408px
          .cover-bg
            width 100%
            height 100%
          .pic
            position absolute
            top 4px
            right 0
            bottom 0
            left 4px
            width 270px
            height 400px
        .mask
          position absolute
          bottom 0
          left 0
          display table
          width 590px
          height 180px
          background rgba(0, 0, 0, .2)
          .name
            width 570px
            padding 10px
            margin-top 60px
            text-align center
            font-size 30px
            color rgba(255, 255, 255, 1)
          .author
            width 570px
            padding 10px
            text-align center
            font-size 22px
            color rgba(255, 255, 255, 1)
      .user-info
        display flex
        width 590px
        height 160px
        margin 80px auto 0 auto
        .pointer
          width 22px
          height 22px
          margin 14px 12px
        .user
          width 330px
          height 160px
          .name
            width 310px
            padding 10px
            font-size 30px
            color rgba(51, 51, 51, 1)
            font-weight bold
            overflow hidden
          .desc
            width 310px
            padding 10px
            margin-top 10px
            font-size 26px
            color rgba(51, 51, 51, 1)
            overflow hidden
        .qr-code
          position relative
          width 200px
          height 160px
          margin-left 14px
          .qr-code-bg
            position absolute
            top 0
            left 0
            width 100%
            height 100%
          .qr-code-pic
            position absolute
            z-index 100
            top 10px
            left 10px
            bottom 0
            width 140px
            height 140px
  .share-canvas
    width 610px
    height 930px
    margin-top 20px
    .share-canvas-img
      width 100%
      height 100%
  .share-btn
    display flex
    justify-content center
    align-items center
    width 610px
    height 80px
    margin-top 30px
    border-radius 40px
    background rgba(179, 53, 58, 1)
    .btn-msg
      line-height normal
      font-size 30px
      color rgba(255, 255, 255, 1)
</style>
