import Base from './Base'

export function createMsg (data) {
  return Base.post('/api/v1/msg/create', data)
}

export function userMsgCount (data) {
  return Base.post('/api/v1/msg/user_msg_count', data)
}

export function getMsg (data) {
  return Base.post('/api/v1/msg/get_msg', data)
}

export function getMsgGroup (data) {
  return Base.post('/api/v1/msg/get_msg_group', data)
}
