<template>
  <div class="wrapper">
    <div class="contact">
      <div class="contact-info">
        <div class="info-title">
          <div class="title-icon"></div>
          <div class="title-text">联系客服</div>
        </div>
        <router-link tag="div" to="/personal/question" class="question">
          <div class="question-icon"></div>
          咨询/反馈
        </router-link>
        <div class="tell">电话：{{ agencyInfo.agency_contact_number }}</div>
        <div class="agency">{{ agencyInfo.agency_name }}</div>
      </div>
      <div class="qrcode">
        <div class="qrcode-img-block">
          <img :src="agencyInfo.agency_qr_code" class="qrcode-img" alt=""/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PersonalContact',
  props: {
    agencyInfo: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/mixin"
.wrapper
  width 690px
  padding 30px

  .contact
    display flex
    align-items center
    width 690px
    box-shadow 0px 5px 10px 0px rgba(0, 0, 0, 0.02)
    border-radius 10px
    overflow hidden
    background rgba(255, 255, 255, 1)

    .contact-info
      width 463px
      height 330px

      .info-title
        display flex
        align-items center
        width 205px
        height 59px
        margin-top 23px
        bg-image('~@/assets/img/personal/bg')
        background-size 100% 100%
        background-repeat no-repeat

        .title-icon
          width 22px
          height 26px
          margin 0 14px 0 30px
          bg-image('~@/assets/img/personal/contact')
          background-size 100% 100%
          background-repeat no-repeat

        .title-text
          width 139px
          line-height normal
          font-size 28px
          color rgba(189, 189, 189, 1)
          font-weight bold

      .question
        display flex
        align-items center
        justify-content center
        width 260px
        height 60px
        margin-left 30px
        margin-top 30px
        line-height normal
        font-size 26px
        color rgba(189, 189, 189, 1)
        border 1px solid rgba(189, 189, 189, 1)

        .question-icon
          width 24px
          height 24px
          margin-right 20px
          bg-image('~@/assets/img/personal/question')
          background-size 100% 100%
          background-repeat no-repeat

      .tell
        width 397px
        height 26px
        line-height 26px
        font-size 26px
        color rgba(51, 51, 51, 1)
        margin-top 30px
        padding-left 30px

      .agency
        width 397px
        height 26px
        line-height 26px
        font-size 26px
        color rgba(51, 51, 51, 1)
        margin-top 20px
        padding-left 30px

    .qrcode
      display flex
      justify-content center
      align-items center
      width 212px
      height 212px
      border-radius 10px
      border 1px solid rgba(189, 189, 189, 1)

      .qrcode-img-block
        width 200px
        height 200px
        overflow hidden

        .qrcode-img
          width 100%
          height 100%
</style>
