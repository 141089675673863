import CryptoJS from 'crypto-js'

const key = CryptoJS.enc.Utf8.parse('code-read-123!@#')
const iv = CryptoJS.enc.Utf8.parse('123!@#-code-read')
let encrypt = (str) => {
  let encrypt = CryptoJS.AES.encrypt(str, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  return CryptoJS.enc.Base64.stringify(encrypt.ciphertext)
}
let decrypt = (str) => {
  let response = CryptoJS.AES.decrypt(str, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  })
  let responseString = response.toString(CryptoJS.enc.Utf8)
  return JSON.parse(responseString)
}
export default {
  encrypt,
  decrypt
}
