<template>
  <div class="user">
    <div class="user-info">
      <router-link tag="div" to="/login" class="avatar">
        <img :src="userAvatar" class="avatar-img">
      </router-link>
      <div class="info">
        <div class="name">{{ userName }}</div>
        <div class="user-ext">
          <div class="more" v-if="userReadCard">
            <div class="more-text">已绑定读者证</div>
          </div>
          <router-link tag="div" to="/login" class="more" v-else>
            <div class="more-text">绑定读者证</div>
            <div class="more-icon"></div>
          </router-link>
          <!--          <div class="score">-->
          <!--            <div class="score-icon">-->
          <!--              <div class="icon"></div>-->
          <!--            </div>-->
          <!--            <div class="score-text">积分150</div>-->
          <!--          </div>-->
          <router-link class="msg" tag="div" to="/personal/msg">
            <div class="msg-icon" v-show="userMsgCount<=0"></div>
            <div class="msg-icon-active" v-show="userMsgCount>0"></div>
          </router-link>
        </div>
      </div>
      <router-link tag="div" to="/personal/sign-in21day" class="sign">
        <div class="sign-icon"></div>
        <div class="sign-text">签到</div>
      </router-link>
    </div>
    <div class="info-bottom">
      <div class="bot-block">
        <div class="bot-body">
          <div class="num">{{ readNum }}本书</div>
          <div class="desc">阅读</div>
        </div>
      </div>
      <div class="bot-block">
        <div class="bot-body">
          <div class="num">{{ recommendNum }}人</div>
          <div class="desc">推广读者</div>
        </div>
         <router-link class="msg" tag="div" to="/personal/my-promotion">
           <div class="bot-pic" ></div>
         </router-link>

      </div>
    </div>
    <img class="toreaderData" src="~@/assets/img/personal/toreaderData.png" @click="toreaderData">
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'PersonalUser',
  props: {
    userName: {
      type: String,
      default: ''
    },
    userAvatar: {
      type: String,
      default: ''
    },
    userReadCard: {
      type: String,
      default: ''
    },
    readNum: {
      type: Number,
      default: 0
    },
    recommendNum: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters([
      'userMsgCount', 'openid'
    ])
  },
  methods: {
    // 分享生成
    gotoPic () {
      this.$router.push('/my-promotions')
    },
    toreaderData () {
      let url = 'https://soft.nmgcnt.com/msyd/getcodeurl3.php?rurl=http%3A%2F%2Freaderportrait.nmgcnt.com%2Fmweb%2F%23%2Fread'
      // url = 'http://readerportrait.nmgcnt.com/mweb?openid=' + this.openid
      window.location.href = url
    }

  }
}
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/mixin"
.user
  display inline-block
  width 750px
  height 346px
  bg-image('~@/assets/img/personal/user-bg')
  background-size 100% 100%
  background-repeat no-repeat

  .user-info
    display flex
    width 750px
    height 140px
    margin-top 35px

    .avatar
      width 140px
      height 140px
      margin-left 40px
      border-radius 50%
      overflow hidden

      .avatar-img
        width 100%
        height 100%

    .info
      width 438px
      height 140px

      .name
        display flex
        align-items center
        width 410px
        height 44px
        margin-top 24px
        margin-left 28px
        font-size 40px
        font-weight 500
        color rgba(51, 51, 51, 1)
        no-wrap()

      .user-ext
        display flex
        width 500px
        height 42px
        margin-top 36px

        .more
          display flex
          align-items center
          height 26px
          margin-top 8px

          .more-text
            line-height normal
            font-size 26px
            font-weight 500
            color rgba(102, 102, 102, 1)
            margin-left 30px

          .more-icon
            width 13px
            height 24px
            margin-top 1px
            margin-left 15px
            bg-image('~@/assets/img/personal/more')
            background-size 100% 100%
            background-repeat no-repeat

        .score
          display flex
          width 176px
          height 42px
          margin-left 31px

          .score-icon
            width 42px
            height 42px
            box-shadow 0px 5px 10px 0px rgba(0, 0, 0, 0.02)
            border-radius 50%
            background rgba(255, 255, 255, 1)

            .icon
              width 22px
              height 20px
              margin-top 11px
              margin-left 10px
              bg-image('~@/assets/img/personal/score')
              background-size 100% 100%
              background-repeat no-repeat

          .score-text
            width 126px
            height 24px
            font-size 24px
            font-weight 500
            color rgba(102, 102, 102, 1)
            margin-top 2px
            margin-left -40px
            padding 8px 0 6px 48px
            background rgba(255, 255, 255, .4)
            border-radius 19px

        .msg
          display flex
          align-items center
          justify-content center
          width 42px
          height 42px
          margin-left 16px
          background rgba(255, 255, 255, 1)
          border-radius 50%

          .msg-icon
            width 28px
            height 22px
            bg-image('~@/assets/img/personal/msg')
            background-size 100% 100%
            background-repeat no-repeat

          .msg-icon-active
            position relative
            left 2px
            width 28px
            height 22px
            bg-image('~@/assets/img/personal/msg-active')
            background-size 100% 100%
            background-repeat no-repeat

    .sign
      display flex
      width 102px
      height 44px
      background #FFFFFF
      box-shadow 0px 5px 10px 0px rgba(0, 0, 0, 0.02)
      border-radius 10px
      margin-top 23px

      .sign-icon
        width 20px
        height 22px
        margin 10px 6px 0 18px
        bg-image('~@/assets/img/personal/sign3')
        background-size 100% 100%
        background-repeat no-repeat

      .sign-text
        display flex
        align-items center
        line-height normal
        font-size 22px
        font-weight 500
        color rgba(179, 53, 58, 1)
  .toreaderData{
    width:calc(100%-60px);
    height:99px;
    margin-left:30px;
    margin-top:10px;
  }
  .info-bottom
    display flex
    width 100%
    height 146px
    margin-top 27px

    .bot-block
      position relative
      width 315px
      height 146px
      background rgba(255, 255, 255, .4)
      border-radius 10px
      margin-left 40px

      .bot-body
        display inline-block
        width 335px
        height 136px
        margin-top 8px
        margin-left -10px
        background rgba(255, 255, 255, 1)
        box-shadow 0px 5px 10px 0px rgba(0, 0, 0, 0.02)
        border-radius 10px

        .num
          width 335px
          height 36px
          line-height 36px
          text-align center
          margin-top 29px
          font-size 36px
          color rgba(179, 53, 58, 1)

        .desc
          width 335px
          height 24px
          line-height 24px
          text-align center
          margin-top 30px
          font-size 24px
          color rgba(51, 51, 51, .6)

      .bot-pic
        position absolute
        top 0
        right -10px
        width 106px
        height 76px
        //background #f00
        bg-image('~@/assets/img/personal/t')
        background-size 100% 100%
        background-repeat no-repeat
</style>
