import Vue from 'vue'
import VueRouter from 'vue-router'
import { routes } from '@/router/routes'
import store from '@/store'
import * as types from '@/store/mutation-types'
import { appInit, wxInit, wxShare } from '@/utils/init'
import { userMsgCount } from '@/api/Msg'
import { spread } from '@/api/Cuser'
import { activityspread } from '@/api/Activity'

Vue.use(VueRouter)

const router = new VueRouter({
  routes
})
router.beforeEach(async (to, from, next) => {
  console.log('123123', to.query)
  let agencyGuid = to.query.agency_guid
  if (agencyGuid && agencyGuid !== store.getters.agencyGuid) {
    store.commit(types.SET_AGENCY_GUID, agencyGuid)
  }
  if (!store.getters.agencyGuid) {
    store.commit(types.SET_AGENCY_GUID, '25a2e855-64ea-4e8b-dfc5-a50e7c382369')
  }
  if (to.query.openid) {
    await store.dispatch('setUserInfo', to.query).then(() => {
      delete to.query.openid
      delete to.query.c_user_dzz
      delete to.query.c_user_id
      delete to.query.wxname
      delete to.query.wximg
      return next(to)
    })
  }
  let readercode = to.query.reader_code
  if (readercode && readercode === 'NT0000016699' && !store.getters.userReadCard) {
    store.commit(types.SET_USER_READ_CARD, 'NT0000016699')
  }
  if (store.getters.userGuid && store.getters.userGuid !== 'undefined') {
    next()
  } else {
    appInit(store.getters.agencyGuid, to.path, '')
  }
})
router.afterEach((to, from) => {
  const { meta } = to
  if (meta) {
    const { title } = meta
    if (title) {
      document.title = title
    }
  }
  window.scrollTo(0, 0)
  wxInit()

  const { acguid } = to.query
  if (!acguid) {
    wxShare(store.getters.agencyGuid, to.path, store.getters.userGuid)
    userMsgCount({
      c_user_guid: store.getters.userGuid
    }).then(res => {
      const { data } = res
      store.state.userMsgCount = data
    })
  }
  const { guide } = to.query
  const path = to.path
  let nacguid = to.query.nacguid ? to.query.nacguid : null
  if ((path === '/home/answer-home-new' || path === '/home/answer-home') && nacguid) {
    to.query.activity_guid = to.query.nacguid
  }
  if (guide && store.getters.userGuid) {
    let nactype = to.query.nactype ? to.query.nactype : null
    let noarr = ['ac7082e3-cbcf-b090-3087-32c09487ea46']
    if (nacguid && nactype && !noarr.includes(nacguid)) {
      activityspread({
        c_user_guid: guide,
        spread_guid: store.getters.userGuid,
        activity_guid: nacguid,
        activity_type_new: nactype
      })
    } else {
      spread({
        c_user_guid: guide,
        spread_guid: store.getters.userGuid,
        activity_guid: nacguid,
        activity_type_new: nactype
      })
    }
  }
})
export default router
