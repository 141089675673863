<template>
  <div class="container">
    <div
      class="re-list"
      v-for="(item,index) in recommend"
      :key="index"
    >
      <div class="ranking" v-if="index===2">
        <home-ranking/>
      </div>
      <div class="list" v-if="item.resources.length>0">
        <div class="header">
          <div class="header-text">{{ item.recommend_name }}</div>
          <router-link tag="div"
                       :to="`/home/recommend-resources/${item.recommend_guid}/${item.recommend_template_type}`"
                       class="header-btn">更多
          </router-link>
        </div>
        <div class="template_1" v-if="item.recommend_template_type === 1">
          <div
            class="item"
            v-for="(vo,key) in item.resources"
            :key="key"
            @click="handleClick(vo.resources_guid,vo.resources_type,vo.resources_name)"
          >
            <div class="pic">
              <img class="pic-img" :src="vo.media_file" alt=""/>
            </div>
            <div class="info">{{ vo.resources_name }}</div>
          </div>
        </div>
        <div class="template_2" v-else-if="item.recommend_template_type === 2">
          <div
            class="item"
            v-for="(vo,key) in item.resources"
            :key="key"
            @click="handleClick(vo.resources_guid,vo.resources_type,vo.resources_name)"
          >
            <div class="pic">
              <img class="pic-img" :src="vo.media_file" alt=""/>
            </div>
            <div class="info">
              <div class="name">{{ vo.resources_name }}</div>
              <div class="desc">{{ vo.resources_desc }}</div>
              <div class="author-wrapper">
                <div class="icon"></div>
                <div class="author">{{ vo.resources_author }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="template_3" v-else-if="item.recommend_template_type === 3">
          <div
            class="item"
            v-for="(vo,key) in item.resources"
            :key="key"
            @click="handleClick(vo.resources_guid,vo.resources_type,vo.resources_name)"
          >
            <div class="pic">
              <img class="pic-img" :src="vo.media_file" alt=""/>
            </div>
            <div class="info">
              <div class="name">{{ vo.resources_name }}</div>
              <div class="author-wrapper">
                <div class="icon"></div>
                <div class="author">{{ vo.resources_author }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="template_4" v-else-if="item.recommend_template_type === 4">
          <div
            class="item"
            v-for="(vo,key) in item.resources"
            :key="key"
            @click="handleClick(vo.resources_guid,vo.resources_type,vo.resources_name)"
          >
            <div class="pic">
              <img class="pic-img" :src="vo.media_file" alt=""/>
            </div>
            <div class="info">
              <div class="desc">{{ vo.resources_name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getRecommendResources, getRecommends } from '@/api/Recommends'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { Toast } from 'vant'
import { getAudios } from '@/api/Resources'
import { appInit } from '@/utils/init'
import HomeRanking from './Ranking'

export default {
  name: 'HomeRecommends',
  components: {
    HomeRanking
  },
  data () {
    return {
      recommend: []
    }
  },
  computed: {
    ...mapGetters([
      'agencyGuid',
      'userGuid'
    ])
  },
  mounted () {
    this._getRecommends()
    const { guid } = this.$route.query
    if (guid) {
      this.setAgencyGuid('f3c71205-2053-cd07-55d4-ca35038ca58e')
      this.handleClick(guid, 2)
    }
  },
  methods: {
    _getRecommends () {
      getRecommends({
        agency_guid: this.agencyGuid
      }).then(res => {
        const {
          code,
          data
        } = res
        if (code === 200) {
          this.recommend = data
          data.forEach(item => {
            this._getRecommendResources(item)
          })
        }
      })
    },
    _getRecommendResources (item) {
      let limit = 4
      if (item.recommend_template_type === 2 || item.recommend_template_type === 3) {
        limit = 3
      }
      getRecommendResources({
        recommend_guid: item.recommend_guid,
        page: 1,
        limit: limit
      }).then(result => {
        const {
          code,
          data
        } = result
        if (code === 200) {
          this.recommend.filter(vo => {
            if (vo.recommend_guid === item.recommend_guid) {
              vo.resources = data.list
            }
            this.$emit('refresh')
          })
        }
      })
    },
    handleClick (guid, type, name) {
      if (type === 1 || type === 5) { // 电子书
        this.$router.push(`/home/book-detail/${guid}/${name}`)
      }
      if (type === 2 || type === 6) { // 听书
        Toast.loading({
          duration: 0,
          forbidClick: true,
          message: '加载中...'
        })
        getAudios({
          resources_guid: guid,
          c_user_guid: this.userGuid
        }).then(res => {
          const {
            code,
            data
          } = res
          if (code === 200) {
            if (!data.list.length) {
              return Toast.fail('暂无音频数据')
            }
            this.selectPlay({
              list: data.list,
              index: data.current_index
            })
          } else if (res.code === 401) {
            appInit(this.agencyGuid, this.$route.path)
          } else if (res.code === 402) {
            this.$router.push({
              name: 'Login',
              params: {
                link: this.$route.path
              }
            })
          } else {
            Toast.fail(res.msg)
          }
          Toast.clear()
        })
      }
      if (type === 3) { // 视频
        this.$router.push(`/home/video-detail/${guid}/${name}`)
      }
    },
    ...mapActions([
      'selectPlay'
    ]),
    ...mapMutations({
      setAgencyGuid: 'SET_AGENCY_GUID'
    })
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.container
  display flex
  flex-wrap wrap
  justify-content center
  align-items center
  width 100%

  .re-list
    width 690px

    .ranking
      width 690px

    .list
      width 690px
      margin-top 30px
      box-shadow 0 5px 10px 0 rgba(0, 0, 0, 0.02)
      border-radius 10px
      background rgba(255, 255, 255, 1)

      .header
        display flex
        align-items center
        width 650px
        padding 0 20px
        height 85px

        .header-text
          width 560px
          heght 85px
          line-height normal
          font-size 34px
          font-weight bold
          color #333
          padding-left 20px
          no-wrap()

        .header-btn
          display flex
          justify-content center
          align-items center
          width 90px
          height 38px
          line-height normal
          margin-left auto
          border 2px solid #B4B4B4
          border-radius 19px
          font-size 26px
          color #B4B4B4

      .template_1
        display flex
        align-items center
        justify-content space-around
        width 650px
        padding 0 20px 20px 20px

        .item
          display flex
          flex-wrap wrap
          justify-content center
          align-items center
          width 152px
          height 307px

          .pic
            width 152px
            height 222px
            border-radius 10px
            overflow hidden

            .pic-img
              width 100%
              height 100%

          .info
            width 140px
            height 60px
            line-height 30px
            font-size 26px
            color rgba(51, 51, 51, 1)
            no-wrap2(2)

      .template_2
        display flex
        flex-wrap wrap
        justify-content center
        width 690px
        padding-bottom 20px

        .item
          display flex
          align-items center
          width 650px
          padding-bottom 20px

          .pic
            width 152px
            height 222px
            border-radius 10px
            overflow hidden

            .pic-img
              width 100%
              height 100%

          .info
            display flex
            flex-wrap wrap
            align-items center
            width 478px
            padding-left 20px
            height 222px

            .name
              width 100%
              line-height normal
              font-size 26px
              color rgba(51, 51, 51, 1)
              no-wrap()

            .desc
              width 100%
              height 72px
              line-height 36px
              font-size 24px
              color rgba(153, 153, 153, 1)
              no-wrap2(2)

            .author-wrapper
              display flex
              align-items center

              .icon
                width 16px
                height 16px
                bg-image('~@/assets/img/author')
                background-size 100% 100%
                background-repeat no-repeat

              .author
                width 452px
                padding-left 10px
                line-height normal
                font-size 22px
                color rgba(153, 153, 153, 1)
                no-wrap()

      .template_3
        display flex
        justify-content space-around
        align-items center
        width 650px
        padding 0 20px 20px 20px

        .item
          width 206px
          height 388px

          .pic
            width 206px
            height 304px
            border-radius 10px
            overflow hidden

            .pic-img
              width 100%
              height 100%

          .info
            display flex
            justify-content center
            align-items center
            flex-wrap wrap
            width 206px
            height 84px

            .name
              width 200px
              line-height normal
              font-size 26px
              color rgba(51, 51, 51, 1)
              no-wrap()

            .author-wrapper
              display flex
              align-items center
              width 200px

              .icon
                width 16px
                height 16px
                bg-image('~@/assets/img/author')
                background-size 100% 100%
                background-repeat no-repeat

              .author
                width 174px
                padding-left 10px
                line-height normal
                font-size 22px
                color rgba(153, 153, 153, 1)
                no-wrap()

      .template_4
        display flex
        justify-content space-between
        align-items center
        flex-wrap wrap
        width 650px
        padding 0 20px

        .item
          position relative
          width 318px
          height 222px
          margin-bottom 20px
          border-radius 10px
          overflow hidden

          .pic
            width 318px
            height 222px

            .pic-img
              width 100%
              height 100%

          .info
            display flex
            align-items center
            position absolute
            bottom 0
            width 318px
            height 62px
            background rgba(0, 0, 0, .5)

            .desc
              width 298px
              padding 0 10px
              line-height normal
              font-size 26px
              color rgba(255, 255, 255, 1)
              no-wrap()
</style>
