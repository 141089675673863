<template>
  <div class="wrapper">
    <div class="read">
      <div class="title">最近阅读</div>
      <div class="list" v-if="readLog.length">
        <div
          class="item"
          v-for="(item,index) in readLog"
          :key="index"
          @click="handleClick(item.resources_guid,item.resources_type_int)"
        >
          <div class="pic">
            <img class="pic-img" alt="" :src="item.resources_cover"/>
          </div>
          <div class="text">{{ item.resources_name }}</div>
        </div>
      </div>
    </div>
    <div class="read">
      <div class="title">最近收听</div>
      <div class="list" v-if="audioLog.length">
        <div
          class="item"
          v-for="(item,index) in audioLog"
          :key="index"
          @click="handleClick(item.resources_guid,item.resources_type_int)"
        >
          <div class="pic">
            <img class="pic-img" alt="" :src="item.resources_cover"/>
          </div>
          <div class="text">{{ item.resources_name }}</div>
        </div>
      </div>
    </div>
    <div class="read">
      <div class="title">最近观看</div>
      <div class="list" v-if="videoLog.length">
        <div
          class="item"
          v-for="(item,index) in videoLog"
          :key="index"
          @click="handleClick(item.resources_guid,item.resources_type_int)"
        >
          <div class="pic">
            <img class="pic-img" alt="" :src="item.resources_cover"/>
          </div>
          <div class="text">{{ item.resources_name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getAudios } from '@/api/Resources'
import { appInit } from '@/utils/init'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PersonalRead',
  props: {
    readLog: {
      type: Array,
      default () {
        return []
      }
    },
    audioLog: {
      type: Array,
      default () {
        return []
      }
    },
    videoLog: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    ...mapGetters(['userGuid'])
  },
  methods: {
    handleClick (guid, type) {
      if (type === 1 || type === 5) { // 电子书
        this.$router.push(`/home/book-detail/${guid}/码上悦读`)
      }
      if (type === 2 || type === 6) { // 听书
        Toast.loading({
          duration: 0,
          forbidClick: true,
          message: '加载中...'
        })
        getAudios({
          resources_guid: guid,
          c_user_guid: this.userGuid
        }).then(res => {
          const {
            code,
            data
          } = res
          if (code === 200) {
            if (!data.list.length) {
              return Toast.fail('暂无音频数据')
            }
            this.selectPlay({
              list: data.list,
              index: data.current_index
            })
          } else if (res.code === 401) {
            appInit(this.agencyGuid, this.$route.path)
          } else if (res.code === 402) {
            this.$router.push({
              name: 'Login',
              params: {
                link: this.$route.path
              }
            })
          } else {
            Toast.fail(res.msg)
          }
          Toast.clear()
        })
      }
      if (type === 3) { // 视频
        this.$router.push(`/home/video-detail/${guid}/码上悦读`)
      }
    },
    ...mapActions([
      'selectPlay'
    ])
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/mixin"
.wrapper
  width 100%
  margin-top 10px

  .read
    display table
    width 690px
    padding-bottom 26px
    margin 0 auto
    margin-bottom 20px
    box-shadow 0px 5px 10px 0px rgba(0, 0, 0, 0.02)
    border-radius 10px
    background rgba(255, 255, 255, 1)

    .title
      width 662px
      height 30px
      line-height 34px
      font-size 30px
      color rgba(51, 51, 51, 1)
      font-weight bold
      padding-left 20px
      margin 30px auto
      border-left 8px solid rgba(179, 53, 58, 1)

    .list
      display flex
      justify-content space-around
      flex-wrap wrap
      width 690px

      .item
        display table
        width 152px
        height 306px
        margin-bottom 20px

        .pic
          width 152px
          height 222px
          border-radius 10px
          overflow hidden

          .pic-img
            width 100%
            height 100%

        .text
          width 152px
          height 60px
          line-height 34px
          font-size 26px
          color rgba(51, 51, 51, 1)
          margin-top 24px
          no-wrap2(2)
</style>
