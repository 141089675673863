<template>
  <div class="wrapper">
    <swiper class="raking-swiper" :options="swiperOption">
      <swiper-slide class="slide">
        <div class="raking-item bg">
          <router-link tag="div" to="/home/reading/resources_favorites_num" class="item-header">
            <div class="header-icon"></div>
            <div class="header-text">热门榜</div>
            <div class="header-more"></div>
          </router-link>
          <div class="item-body">
            <router-link
              tag="div"
              :to="'/home/book-detail/'+item.resources_guid+'/'+item.resources_name"
              class="body-text"
              v-for="(item, index) in hotRaking"
              :key="index"
            >
              <span v-if="index+1 === 1" class="first">
                {{ index + 1 }}.
              </span>
              <span v-else-if="index+1 === 2" class="second">
                {{ index + 1 }}.
              </span>
              <span v-else-if="index+1 === 3" class="third">
                {{ index + 1 }}.
              </span>
              <span v-else>{{ index + 1 }}.</span>
              {{ item.resources_name }}
            </router-link>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="slide">
        <div class="raking-item bg1">
          <router-link tag="div" to="/home/reading/create_time" class="item-header">
            <div class="header-icon1"></div>
            <div class="header-text">新书榜</div>
            <div class="header-more"></div>
          </router-link>
          <div class="item-body">
            <router-link
              tag="div"
              :to="'/home/book-detail/'+item.resources_guid+'/'+item.resources_name"
              class="body-text"
              v-for="(item, index) in newRaking"
              :key="index"
            >
              <span v-if="index+1 === 1" class="first">
                {{ index + 1 }}.
              </span>
              <span v-else-if="index+1 === 2" class="second">
                {{ index + 1 }}.
              </span>
              <span v-else-if="index+1 === 3" class="third">
                {{ index + 1 }}.
              </span>
              <span v-else>{{ index + 1 }}.</span>
              {{ item.resources_name }}
            </router-link>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/swiper-bundle.css'
import { getResourcesPageList } from '@/api/Resources'
import { mapGetters } from 'vuex'

export default {
  name: 'HomeRanking',
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      hotRaking: [],
      newRaking: [],
      swiperOption: {
        slidesPerView: 'auto',
        freeMode: true,
        slideToClickedSlide: true,
        centeredSlides: true,
        centeredSlidesBounds: true
      }
    }
  },
  computed: {
    ...mapGetters([
      'agencyGuid'
    ])
  },
  mounted () {
    this._rankingInit()
  },
  methods: {
    _rankingInit () {
      getResourcesPageList({
        agency_guid: this.agencyGuid,
        resources_type: [1, 5],
        page: 1,
        limit: 5,
        field: 'resources_favorites_num',
        order: 'desc'
      }).then(res => {
        if (res.code === 200) {
          this.hotRaking = res.data.list
        }
      })
      getResourcesPageList({
        agency_guid: this.agencyGuid,
        resources_type: [1, 5],
        page: 1,
        limit: 5,
        field: 'create_time',
        order: 'desc'
      }).then(res => {
        if (res.code === 200) {
          this.newRaking = res.data.list
        }
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/mixin"
.wrapper
  width 100%
  height 440px
  margin-top 30px

  .raking-swiper
    display flex
    width 100%
    height 440px

    .slide
      width 470px
      height 440px
      .raking-item
        width 440px
        height 440px
        //margin-left 30px
        border-radius 10px
        overflow hidden
        background-size 100% 100%
        background-repeat no-repeat

        &.bg
          bg-image('~@/assets/img/raking-bg')

        &.bg1
          bg-image('~@/assets/img/raking-bg1')

        .item-header
          display flex
          align-items center
          width 440px
          height 76px
          border-bottom 2px solid rgba(237, 241, 249, 1)

          .header-icon
            width 22px
            height 27px
            margin-left 20px
            bg-image('~@/assets/img/raking-icon')
            background-size 100% 100%
            background-repeat no-repeat

          .header-icon1
            width 22px
            height 26px
            margin-left 20px
            bg-image('~@/assets/img/raking-icon1')
            background-size 100% 100%
            background-repeat no-repeat

          .header-text
            width 300px
            height 76px
            line-height 76px
            font-size 34px
            font-weight bold
            color #333
            margin-left 13px

          .header-more
            width 14px
            height 21px
            margin 0 24px 0 auto
            bg-image('~@/assets/img/raking-more')
            background-size 100% 100%
            background-repeat no-repeat

        .item-body
          width 440px
          height 362px

          .body-text
            width 420px
            height 26px
            padding-left 20px
            margin-top 33px
            line-height 30px
            font-size 26px
            color rgba(51, 51, 51, 1)
            no-wrap()

            &:nth-child(1)
              margin-top 26px

            .first
              color rgba(234, 61, 14, 1)

            .second
              color rgba(234, 149, 14, 1)

            .third
              color rgba(234, 205, 14, 1)
</style>
