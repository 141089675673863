<template>
  <div class="nav">
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide
        class="nav-item"
        v-for="(item, index) in navList"
        :key="index"
        :class="{active: index === active}">
        {{ item.category_name }}
        <div :class="{'active-border': index === active}"></div>
      </swiper-slide>
    </swiper>
    <div class="activity-type">
      <div class="type-list">
        <div class="type-block" v-for="(item, index) in typeList" :key="index" @click="typeActive = index">
          <div class="type-item" :class="{active: index === typeActive}">{{ item }}</div>
          <div class="type-bor" v-if="index !== typeList.length - 1"></div>
        </div>
      </div>
      <div class="addr">
        <div class="icon"></div>
        <div class="text" v-show="cityName === '-'">定位中...</div>
        <div class="text" v-show="cityName && cityName !== '-'">{{ cityName }}</div>
        <div class="text" v-show="!cityName" @click="getLocation">定位失败</div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

import 'swiper/swiper-bundle.css'

export default {
  name: 'ActivityNav',
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    navList: {
      type: Array,
      default: null
    },
    cityName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        freeMode: true,
        slideToClickedSlide: true,
        on: {
          click: (swiper) => {
            this.active = swiper.clickedIndex
          }
        }
      },
      active: 0,
      typeActive: 0,
      typeList: [
        '全区活动',
        '本地活动',
        '历史活动'
      ]
    }
  },
  methods: {
    getLocation () {
      this.$emit('getLocation')
    }
  },
  watch: {
    active () {
      this.$emit('changeCategory', this.navList[this.active].category_guid)
    },
    typeActive (index) {
      this.$emit('changeLevel', index)
    }
  }
}
</script>

<style lang="stylus" scoped>
@import "~@/assets/stylus/mixin"
.nav
  width 100%
  height 164px

  .swiper
    width 100%
    height 76px
    background rgba(255, 255, 255, 1)
    border-bottom 2px solid rgba(234, 234, 234, 1)

    .nav-item
      width auto
      height 28px
      text-align center
      font-size 28px
      color rgba(51, 51, 51, 1)
      padding 32px 34px 16px 34px

      &.active
        position relative
        top -2px
        font-size 34px
        color rgba(179, 53, 58, 1)
        padding 29px 34px 13px 34px

    .active-border
      width 30px
      height 6px
      background rgba(179, 53, 58, 1)
      border-radius 3px
      margin 9px auto 0 auto

  .activity-type
    display flex
    width 750px
    height 60px
    margin-top 26px

    .type-list
      display flex
      width 405px
      height 60px
      margin-left 30px
      border-radius 10px
      background #fff

      .type-block
        display flex
        align-items center
        width 135px
        height 60px
        line-height normal

        .type-item
          width 135px
          text-align center
          font-size 26px
          color rgba(51, 51, 51, 1)
          font-weight 500

          &.active
            padding 12px 0
            color #fff
            background rgba(179, 53, 58, 1)
            border-radius 10px

        .type-bor
          width 1px
          height 39px
          bg-image('~@/assets/img/activity/line')
          background-size 100% 100%
          background-repeat no-repeat

    .addr
      display flex
      width auto
      height 22px
      margin-top 19px
      margin-left auto
      margin-right 31px

      .icon
        width 18px
        height 22px
        bg-image('~@/assets/img/activity/addr')
        background-size 100% 100%
        background-repeat no-repeat

      .text
        width auto
        height 22px
        line-height 22px
        font-size 22px
        color rgba(153, 153, 153, 1)
        margin-left 10px
</style>
