const state = {
  agencyGuid: localStorage.agencyGuid ? localStorage.agencyGuid : '', // 机构主键
  userGuid: localStorage.userGuid ? localStorage.userGuid : '', // 用户主键
  userName: localStorage.userName ? localStorage.userName : '', // 用户姓名
  openid: localStorage.openid ? localStorage.openid : '', // openid
  userAvatar: localStorage.userAvatar ? localStorage.userAvatar : '', // 用户头像
  userReadCard: localStorage.getItem('userReadCard') ? localStorage.getItem('userReadCard') : '', // 用户读者证
  playing: false, // 播放状态
  fullScreen: false, // 播放器展开状态
  playList: [], // 播放列表
  currentIndex: -1, // 当前播放索引
  searchList: localStorage.searchList ? JSON.parse(localStorage.searchList) : [], // 搜索记录
  userMsgCount: 0, // 用户消息
  userCityName: '-', // 用户所在城市
  activity: localStorage.activity ? JSON.parse(localStorage.activity) : {}
}
export default state
