<template>
  <div id="app">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
    <tab></tab>
    <player-detail></player-detail>
    <fu></fu>
  </div>
</template>

<script>
import tab from '@/views/tab/Index'
import playerDetail from '@/views/player_detail/PlayerDetail'
import Fu from '@/components/activity/Fu'
import { mapGetters, mapMutations } from 'vuex'
import { appInit } from '@/api/App'
import wx from 'weixin-js-sdk'

export default {
  name: 'APP',
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'agencyGuid',
      'userGuid'
    ])
  },
  mounted () {
    this._appInit()
  },
  components: {
    tab,
    playerDetail,
    Fu
  },
  methods: {
    _appInit () {
      // 设置机构访问次数,用户机构信息
      wx.ready(() => {
        wx.getLocation({
          type: 'wgs84',
          success: (location) => {
            appInit({
              c_user_guid: this.userGuid,
              agency_guid: this.agencyGuid,
              c_user_lng: location.longitude,
              c_user_lat: location.latitude
            }).then((res) => {
              const {
                code,
                data
              } = res
              if (code === 200) {
                this.userCityName(data.city)
              }
            })
          }
        })
      })
    },
    ...mapMutations({
      userCityName: 'SET_USER_CITY_NAME'
    })
  }
}
</script>

<style lang="stylus" scoped>
</style>
