import Base from './Base'

export function collect (data) {
  return Base.post('/api/v1/collects/collect', data)
}

export function rank (data) {
  return Base.post('/api/v1/collects/rank', data)
}
export function useragency (data) {
  return Base.post('/api/v1/collects/useragency', data)
}
export function setagencyco (data) {
  return Base.post('/api/v1/collects/setagency', data)
}
