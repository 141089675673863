<template>
  <div class="container">
   <!-- <router-link
        to="/home/dbf-rank"
    >
      <img
          class="advert"
          src="https://msyd-1304515158.cos.ap-beijing.myqcloud.com/msyd-20230620/advert1.png"
          alt=""
      >
    </router-link> -->
    <!-- 活动导航入口 -->
     <!-- <router-link to="/personal/sign-in" >   /personal/sign-in21day"  sign-in21day/lottery -->
      <!-- <router-link to="/home/answer-home" > -->
    <div v-for="(item, index) in opendata" :key="index">
      <router-link :to="{path:'/personal/sign-in21day',query:{'activity_guid':item.activity_guid}}" v-if=" item.activity_type_new === 1">
        <img class="advert" :src="item.activity_setting.activity_in_img" style="margin-bottom: 15px;" alt="" />
      </router-link>
      <router-link :to="{path:'/home/dbf-rank',query:{'activity_guid':item.activity_guid}}" v-if=" item.activity_type_new === 2">
        <img class="advert" :src="item.activity_setting.activity_in_img" style="margin-bottom: 15px;" alt="" />
      </router-link>
      <template v-if="item.activity_type_new === 3">
        <router-link :to="{path:'/home/answer-home-new',query:{'activity_guid':item.activity_guid}}" v-if="item.activity_guid === 'ac7082e3-cbcf-b090-3087-32c09487ea46'">
          <img class="advert" :src="item.activity_setting.activity_in_img" style="margin-bottom: 15px;" alt="" />
        </router-link>
        <router-link :to="{path:'/home/answer-home',query:{'activity_guid':item.activity_guid}}" v-else>
          <img class="advert" :src="item.activity_setting.activity_in_img" style="margin-bottom: 15px;" alt="" />
        </router-link>
      </template>
    </div>

    <div class="tab-wrapper" v-if="agencyGuid === '25a2e855-64ea-4e8b-dfc5-a50e7c382369'">
      <div class="title">内图数字阅读</div>
      <div class="tab">
        <div
            class="item"
            :class="{active: currentIndex===index}"
            v-for="(item,index) in tab"
            :key="index"
            @click="currentIndex = index"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="nav-wrapper">
        <a :href="item.link" class="nav" v-for="(item,index) in tab[currentIndex].list" :key="index">
          <div class="ico">
            <img class="img" :src="item.img" alt="">
          </div>
          <div class="name">{{ item.name }}</div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { openninglist, hylogin } from '@/api/Activity'

export default {
  name: 'IconTab',
  computed: {
    ...mapGetters(['agencyGuid', 'userGuid'])
  },
  data () {
    return {
      currentIndex: 0,
      opendata: [],
      activity_guid: '',
      tab: [
        {
          name: '图书',
          list: [
            {
              name: 'QQ阅读',
              link: 'http://soft.nmgcnt.com/wxsso',
              img: 'https://msyd-1304515158.cos.ap-beijing.myqcloud.com/assets/icon-tab/qq-read%402x.png'
            },
            {
              name: '元阅读',
              link: 'https://h5.metareader.cn/?tenantId=101041&appId=keny8a4h',
              img: 'https://msyd-1304515158.cos.ap-beijing.myqcloud.com/assets/icon-tab/yyd%25402x.png'
            }
            // {
            //   name: '掌阅精选',
            //   link: 'https://s.zhangyue.com/channel/index?rentId=100547&appId=c481efd0',
            //   img: 'https://msyd-1304515158.cos.ap-beijing.myqcloud.com/assets/icon-tab/zy%402x.png'
            // },
            // {
            //   name: '博看微书屋',
            //   link: 'http://soft.nmgcnt.com/wxsso_bokan',
            //   img: 'https://msyd-1304515158.cos.ap-beijing.myqcloud.com/assets/icon-tab/bkwsw.png'
            // },
            // {
            //   name: '红色故事绘',
            //   link: 'http://wxhsgsh.zhlhh.com/nmglib',
            //   img: 'https://msyd-1304515158.cos.ap-beijing.myqcloud.com/assets/icon-tab/hsgsh.png'
            // }
          ]
        },
        {
          name: '听书',
          list: [
            // {
            //   name: '云图有声',
            //   link: 'http://yuntuwechat.yuntuys.com/index/MTA4/NTU0',
            //   img: 'https://msyd-1304515158.cos.ap-beijing.myqcloud.com/assets/icon-tab/ytys.png'
            // },
            // {
            //   name: '云图少儿',
            //   link: 'http://yuntuwechat.yuntuys.com/index/MTA2/NTU0',
            //   img: 'https://msyd-1304515158.cos.ap-beijing.myqcloud.com/assets/icon-tab/ytse.png'
            // },
            {
              name: '懒人听书',
              link: 'https://api-library.lrts.me/h5/index.html?subShopId=2235',
              img: 'https://msyd-1304515158.cos.ap-beijing.myqcloud.com/assets/icon-tab/lrts.png'
            }
          ]
        },
        {
          name: '综合',
          list: [
            {
              name: '网上报告厅',
              link: 'https://wb.wap.bjadks.com',
              img: 'https://msyd-1304515158.cos.ap-beijing.myqcloud.com/assets/icon-tab/wsbgt.png'
            },
            {
              name: '职业全能库',
              link: 'https://zyk.wap.bjadks.com',
              img: 'https://msyd-1304515158.cos.ap-beijing.myqcloud.com/assets/icon-tab/zyqnk.png'
            },
            {
              name: '思政云课堂',
              link: 'https://sz.bjadks.com/mobile',
              img: 'https://msyd-1304515158.cos.ap-beijing.myqcloud.com/assets/icon-tab/szykt.png'
            },
            // {
            //   name: '红色记忆',
            //   link: 'http://nmglib.com/web/vr35mob',
            //   img: 'https://msyd-1304515158.cos.ap-beijing.myqcloud.com/assets/icon-tab/hsjy.png'
            // },
            {
              name: '书童AR互动科普',
              link: 'http://office.hist.cc:88/mz/#/mb/typelist',
              img: 'https://msyd-1304515158.cos.ap-beijing.myqcloud.com/assets/icon-tab/ar.png'
            },
            {
              name: '文化信息网',
              link: 'http://www.nmgcnt.com',
              img: 'https://msyd-1304515158.cos.ap-beijing.myqcloud.com/assets/icon-tab/whxxw.png'
            },
            {
              name: '地理百科',
              link: 'http://zgdl.wap.shbk.tech',
              img: 'https://msyd-1304515158.cos.ap-beijing.myqcloud.com/assets/icon-tab/dlbk.png'
            },
            {
              name: '知识视界',
              link: 'http://tact.libvideo.com/Intr/InDZ0728E3.html',
              img: 'https://msyd-1304515158.cos.ap-beijing.myqcloud.com/assets/icon-tab/zssj.png'
            }
          ]
        }
      ]
    }
  },
  mounted () {
    this._hylogin()
    this._openlist()
  },
  methods: {
    _hylogin () {
      let _this = this
      hylogin({ }).then(res => {
        if (res.code === 200) {
          _this.tab[0].list.push({
            name: '超星电子书',
            link: res.data,
            img: 'https://msyd-1304515158.cos.ap-beijing.myqcloud.com/assets/icon-tab/zy%402x.png'
          })
          console.log(_this.tab)
        }
      })
    },
    _openlist () {
      let _this = this
      let agencyguid = localStorage.getItem('agencyGuid')
      openninglist({ agency_guid: agencyguid, c_user_guid: this.userGuid }).then(res => {
        console.log('act---', res.data)
        _this.opendata = res.data
      })
    }
  }
}
</script>

<style scoped lang="stylus">
.container
  display table
  width 100%

  .advert
    display block
    width 690px
    height 174px
    margin 20px auto
    pointer-events none

  .tab-wrapper
    width 690px
    margin 0 auto
    border-radius 10px
    background rgba(255, 255, 255, 1)

    .title
      display flex
      align-items center
      width 100%
      height 100px
      padding 0 40px
      box-sizing border-box
      font-size 32px
      font-weight bold
      color rgba(50, 50, 50, 1)

    .tab
      display flex
      width 100%
      height 56px
      padding 0 20px
      box-sizing border-box

      .item
        display flex
        justify-content center
        align-items center
        width 132px
        height 56px
        margin-right 24px
        font-size 26px
        color rgba(50, 50, 50, 1)
        border-radius 8px
        background rgba(244, 244, 242, 1)

        &.active
          color rgba(255, 255, 255, 1)
          background rgba(191, 47, 46, 1)

    .nav-wrapper
      display flex
      flex-wrap wrap
      width 100%
      padding 30px 10px 0 10px
      box-sizing border-box

      .nav
        display flex
        flex-wrap wrap
        justify-content center
        width 20%

        .ico
          width 110px
          height 110px
          overflow hidden

          .img
            width 100%
            height 100%

        .name
          width 110px
          text-align center
          padding 10px 0
          line-height 26px
          font-size 22px
          color rgba(50, 50, 50, 1)
</style>
