<template>
  <div @touchmove.prevent>
    <activity-nav
      :nav-list="navList"
      @changeCategory="changeCategory"
      :cityName="this.userCityName"
      @getLocation="_getLocation"
      @changeLevel="changeLevel"
    ></activity-nav>
    <scroll
      ref="scroll"
      class="scroll"
      :data="activityList"
      :listen-scroll="true"
      @scroll="scroll"
      :pull-down-refresh="{threshold: 30, stop: 0}"
      @pullingDown="pullDownRefresh"
      :pull-up-load="true"
      @pullingUp="pullUpPush"
    >
      <div>
        <div class="pull-down-wrapper">
          <div class="pull-down-text" v-show="!isPullingDown">下拉即可刷新...</div>
          <div class="pull-down-text" v-show="isPullingDown">释放即可刷新...</div>
        </div>
        <activity-swiper
          v-if="swiperList.length"
          :swiper-list="swiperList"
          :left-swiper="leftSwiper"
          :right-swiper="rightSwiper"
        ></activity-swiper>
        <activity-list v-if="activityList.length" :activity-list="activityList"></activity-list>
        <div class="home-bottom" v-show="showDivider">
          <divider class="divider">已加载全部内容</divider>
        </div>
      </div>
    </scroll>
    <router-view></router-view>
  </div>
</template>

<script>
import Scroll from '@/components/scroll/scroll'
import ActivityNav from './components/Nav'
import ActivitySwiper from './components/Swiper'
import ActivityList from './components/List'
import { Divider, Toast } from 'vant'
import { getClassList } from '@/api/Class'
import { getAdverts } from '@/api/Advert'
import { getActivityPageList } from '@/api/Activity'
import { mapGetters, mapMutations } from 'vuex'
import wx from 'weixin-js-sdk'
import { getAddrByWgs84 } from '@/api/Map'

export default {
  name: 'Activity',
  components: {
    Scroll,
    ActivityNav,
    ActivitySwiper,
    ActivityList,
    Divider
  },
  computed: {
    ...mapGetters([
      'agencyGuid',
      'userCityName'
    ])
  },
  mounted () {
    this._activityInit()
  },
  data () {
    return {
      navList: [], // 分类导航
      swiperList: [], // 轮播列表
      leftSwiper: [], // 左轮播
      rightSwiper: [], // 右轮播
      page: 1, // 分页页数
      limit: 10, // 分页显示条目数
      categoryGuid: '', // 分类
      activityAgency: '', // 机构主键
      isOver: 0, // 历史活动 1-是 0 否
      activityList: [], // 活动列表
      showDivider: false, // 加载全部显示标记
      isPullingDown: false
    }
  },
  methods: {
    _getLocation () {
      wx.ready(() => {
        wx.getLocation({
          type: 'wgs84',
          success: (res) => {
            this._getAddrByWgs84({
              lng: res.longitude,
              lat: res.latitude
            })
          }
        })
      })
    },
    _getAddrByWgs84 (data) {
      getAddrByWgs84(data).then(res => {
        const { code, data } = res
        if (code === 200) {
          this.setUserCityName(data.city)
        } else {
          this.setUserCityName('')
        }
      })
    },
    async _activityInit () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      await Promise.all([
        getClassList({
          category_type: 4
        }).then(res => {
          if (res.code === 200) {
            this.navList.splice(0, this.navList.length)
            this.navList = [
              {
                category_guid: '',
                category_name: '全部'
              }
            ]
            this.navList = this.navList.concat(res.data)
          }
        }),
        getAdverts({
          advert_type: 4,
          advert_agency_guid: this.agencyGuid
        }).then(res => {
          if (res.code === 200) {
            this.leftSwiper.push(res.data[res.data.length - 1])
            res.data.forEach((item, index) => {
              if (index !== res.data.length - 1) {
                this.leftSwiper.push(item)
              }
              if (index !== 0) {
                this.rightSwiper.push(item)
              }
            })
            this.rightSwiper.push(res.data[0])
            this.swiperList = res.data
          }
        }),
        getActivityPageList({
          page: this.page,
          limit: this.limit,
          category_guid: this.categoryGuid,
          activity_agency: this.activityAgency,
          is_over: this.isOver
        }).then(res => {
          if (res.code === 200) {
            this.activityList = this.activityList.concat(res.data.list)
            if (res.data.count <= this.activityList.length) {
              this.showDivider = true
            }
          }
        })
      ])
      Toast.clear()
    },
    _getActivityPageList () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      getActivityPageList({
        page: this.page,
        limit: this.limit,
        category_guid: this.categoryGuid,
        activity_agency: this.activityAgency,
        is_over: this.isOver
      }).then(res => {
        if (res.code === 200) {
          this.activityList = this.activityList.concat(res.data.list)
          if (res.data.count <= this.activityList.length) {
            this.showDivider = true
          }
          Toast.clear()
        }
      })
    },
    scroll (pos) {
      if (pos.y > 30) {
        this.isPullingDown = true
      } else if (pos.y <= 0) {
        this.isPullingDown = false
      }
    },
    pullDownRefresh () {
      this.page = 1
      this.activityList.splice(0, this.activityList.length)
      this.showDivider = false
      this._activityInit()
    },
    pullUpPush () {
      if (this.showDivider) {
        return true
      }
      this.page++
      this._getActivityPageList()
    },
    changeCategory (categoryGuid) {
      this.categoryGuid = categoryGuid
      this.page = 1
      this.activityList.splice(0, this.activityList.length)
      this.showDivider = false
      this._getActivityPageList()
    },
    changeLevel (index) {
      if (index === 0) {
        this.isOver = 0
        this.activityAgency = ''
      } else if (index === 1) {
        this.isOver = 0
        this.activityAgency = this.agencyGuid
      } else {
        this.isOver = 1
        this.activityAgency = ''
      }
      this.page = 1
      this.showDivider = false
      this.activityList.splice(0, this.activityList.length)
      this._getActivityPageList()
    },
    ...mapMutations({
      setUserCityName: 'SET_USER_CITY_NAME'
    })
  }
}
</script>

<style lang='stylus' scoped>
.scroll
  position absolute
  top 164px
  left 0
  right 0
  bottom 97px
  bottom calc(constant(safe-area-inset-bottom) + 97px)
  bottom calc(env(safe-area-inset-bottom) + 97px)
  overflow hidden

  .pull-down-wrapper
    display flex
    justify-content center
    align-items center
    width 750px
    height 70px
    margin-top -70px

    .pull-down-text
      line-height normal
      font-size 22px
      color rgba(153, 153, 153, 1)

  .home-bottom
    wdith 750px
    height 70px
    padding-top 30px

    .divider
      width 300px
      height 22px
      font-size 22px
      color rgba(153, 153, 153, 1)
      margin 0 auto
      border-color rgba(153, 153, 153, 1)
</style>
