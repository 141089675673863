import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/stylus/index.styl'
import { Swiper as SwiperClass, Pagination, Controller, Autoplay, EffectFade } from 'swiper/core'
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter'
import 'swiper/swiper-bundle.css'
import dbfToast from '@/components/dbf-toast'
import questionPopup from '@/components/question-popup'
// import VConsole from 'vconsole'
// Vue.prototype.$vconsole = new VConsole()
// import { Loading } from 'vant'
import { Form, Field, Button, DatetimePicker, ActionSheet, Loading, Picker, Search, Popup } from 'vant'
// import APlayer from '@moefe/vue-aplayer'
// Vue.use(APlayer, {
//   productionTip: true
// })

SwiperClass.use([Pagination, Controller, Autoplay, EffectFade])
Vue.use(getAwesomeSwiper(SwiperClass))
Vue.config.productionTip = false
Vue.use(dbfToast)
Vue.use(questionPopup)
Vue.use(Loading)
Vue.use(Form)
Vue.use(Field)
Vue.use(Button)
Vue.use(DatetimePicker)
Vue.use(ActionSheet)
Vue.use(Picker)
Vue.use(Search)
Vue.use(Popup)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
