<template>
  <div class="container">
    <router-link tag="div" to="/home/article-list" class="bulletin" v-if="advertList.length">
      <div class="icon"></div>
      <swiper class="content-swiper" :options="swiperOption">
        <swiper-slide
          class="swiper-no-swiping content"
          v-for="(item,index) in advertList"
          :key="index"
        >
          {{ item.article_title }}
        </swiper-slide>
      </swiper>
    </router-link>
    <div class="block">
      <router-link tag="div" to="/home/article/agency_con" class="item">
        <div class="info">
          <div class="info-spelling">TUSHUGUANJIANJIE</div>
          <div class="info-text">图书馆简介</div>
        </div>
        <div class="item-icon desc"></div>
      </router-link>
      <router-link tag="div" to="/home/article-list" class="item">
        <div class="info">
          <div class="info-spelling">DUZHEFUWU</div>
          <div class="info-text">读者服务</div>
        </div>
        <div class="item-icon help"></div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { getArticles } from '@/api/Articles'
import { mapGetters } from 'vuex'

export default {
  name: 'HomeBulletin',
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      swiperOption: {
        direction: 'vertical',
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        observer: true,
        observeParents: true,
        noSwiping: true,
        loop: true
      },
      advertList: []
    }
  },
  computed: {
    ...mapGetters([
      'agencyGuid'
    ])
  },
  mounted () {
    this._getArticles()
  },
  methods: {
    _getArticles () {
      getArticles({
        // article_type: 1,
        article_agency: this.agencyGuid,
        article_display_type: 1,
        page: 1,
        limit: 5
      }).then(res => {
        const { code, data } = res
        if (code === 200) {
          this.advertList = data.data
        }
      })
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.container
  display table
  margin-top 24px
  width 100%

  .bulletin
    display flex
    align-items center
    width 642px
    padding 0 24px
    height 80px
    margin 0 auto 24px auto
    border-radius 10px
    background rgba(179, 53, 58, .1)

    .icon
      width 32px
      height 30px
      bg-image('~@/assets/img/home/speaker')
      background-size 100% 100%
      background-repeat no-repeat

    .content-swiper
      width 534px
      height 26px
      margin-left 14px

      .content
        display flex
        align-items center
        font-size 26px
        line-height normal
        color rgba(179, 53, 58, 1)
        no-wrap()

  .block
    display flex
    width 690px
    margin 0 auto

    .item
      display flex
      align-items center
      width 338px
      height 110px
      box-shadow 0 5px 10px 0 rgba(0, 0, 0, 0.02)
      border-radius 10px
      background rgba(255, 255, 255, 1)

      &:first-child
        margin-right 14px

      .info
        display flex
        flex-wrap wrap
        align-items center
        justify-content center
        width 214px
        height 60px
        border-right 2px solid rgba(51, 51, 51, .08)

        .info-spelling
          width 100%
          text-align center
          font-size 16px
          color rgba(136, 136, 136, 1)

        .info-text
          width 100%
          text-align center
          font-size 28px
          font-weight bold
          color rgba(51, 51, 51, 1)

      .item-icon
        width 44px
        height 44px
        margin-left 38px

        &.desc
          bg-image('~@/assets/img/home/desc')
          background-size 100% 100%
          background-repeat no-repeat

        &.help
          bg-image('~@/assets/img/home/help')
          background-size 100% 100%
          background-repeat no-repeat
</style>
