export const agencyGuid = state => state.agencyGuid
export const userGuid = state => state.userGuid
export const userName = state => state.userName
export const openid = state => state.openid
export const userAvatar = state => state.userAvatar
export const userReadCard = state => state.userReadCard
export const playing = state => state.playing
export const fullScreen = state => state.fullScreen
export const playList = state => state.playList
export const currentIndex = state => state.currentIndex
export const currentSong = (state) => {
  return state.playList[state.currentIndex] || {}
}
export const searchList = state => state.searchList
export const userMsgCount = state => state.userMsgCount
export const userCityName = state => state.userCityName
export const activity = state => state.activity
