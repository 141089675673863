<template>
  <div class="mask" v-show="show">
    <div class="toast" :class="{toast1: num <= 1,toast2: num === 2,toast3: num >= 3}" :style="bg_img" @click="link">
            <div class="tit">集粽成功</div>
            <div class="desc">成功参加“书香北疆，粽情端午” 集粽活动</div>
            <div class="link">查看排行榜</div>
    </div>
    <div class="close" @click="clear"></div>
  </div>
</template>

<script>
import router from '@/router'
import { getCurrentConnect } from '@/api/Activity'

export default {
  name: 'DbfToast',
  data () {
    return {
      show: false,
      num: 2,
      activity: {},
      bg_img: '',
      successarr: [],
      acitivty_guid: ''
    }
  },
  created () {
  },
  mounted () {
    this._getCurrentConnect()
  },
  methods: {
    showToast () {
      const n = 1
      const m = 4
      this.num = Math.round(Math.random() * (m - n) + n)
      this.show = true
    },
    link () {
      router.replace({ path: '/home/dbf-rank', query: { activity_guid: this.activity_guid } })
      this.clear()
    },
    clear () {
      this.show = false
    },
    _getCurrentConnect () {
      let _this = this
      let cuserguid = localStorage.getItem('userGuid')
      let agencyGuid = localStorage.getItem('agencyGuid')
      getCurrentConnect({ activity_type_new: 2, c_user_guid: cuserguid, agency_guid: agencyGuid }).then(res => {
        if (res.code === 200) {
          _this.activity = res.data
          if (res.data.activity_success) {
            _this.activity_guid = res.data.activity_guid
            _this.bg_img = 'background-image:url(' + res.data.activity_success + ')'
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin.styl"
.mask
  position absolute
  left 0
  right 0
  top 0
  bottom 0
  z-index 10000
  background rgba(0, 0, 0, .7)

  .toast
    position relative
    display table
    width 468px
    height 574px
    margin 292px auto 56px auto
    background-size 100% 100%
    background-repeat no-repeat

    &.toast1
      bg-image2('msyd-20230620/toast')

    &.toast2
      bg-image2('msyd-20230620/toast')

    &.toast3
      bg-image2('msyd-20230620/toast')

    .tit
      width 468px
      height 56px
      margin-top 72px
      text-indent:77px;
      font-weight: 800;
      font-size: 55px;
      color: #FFFFFF;
      text-shadow: 1px 4px 4px rgba(18,70,64,0.09);
      letter-spacing:35px;

    .desc
      width 100%
      height 75px
      line-height 42px
      padding 0 30px
      box-sizing border-box
      margin-top 44px
      font-size 28px
      color rgba(95, 151, 74, 1)
      text-align center

    .link
      position absolute
      bottom 0
      left 50%
      transform translate(-50%, 0)
      display flex
      align-items center
      justify-content center
      width 280px
      height 70px
      font-size 30px
      color rgba(255, 255, 255, 1)

  .close
    width 46px
    height 46px
    margin 0 auto
    bg-image2('msyq-question/close')
    background-size 100% 100%
    background-repeat no-repeat
</style>
