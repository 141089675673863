import DbfToast from './DbfToast'

const obj = {}
obj.install = (Vue) => {
  const DbfToastConstructor = Vue.extend(DbfToast)
  const dbfToast = new DbfToastConstructor()
  dbfToast.$mount(document.createElement('div'))
  document.body.appendChild(dbfToast.$el)
  Vue.prototype.$dbfToast = dbfToast
}
export default obj
