<template>
  <div @touchmove.prevent>
    <scroll
      ref="scroll"
      class="scroll"
      :data="bookList"
      :listen-scroll="true"
      @scroll="scroll"
      :pull-down-refresh="{threshold: 30, stop: 0}"
      @pullingDown="pullDownRefresh"
      :pull-up-load="true"
      @pullingUp="pullUpPush"
    >
      <div>
        <div class="pull-down-wrapper">
          <div class="pull-down-text" v-show="!isPullingDown">下拉即可刷新...</div>
          <div class="pull-down-text" v-show="isPullingDown">释放即可刷新...</div>
        </div>
        <books-swiper
          v-if="swiperList.length"
          :swiper-list="swiperList"
          :left-swiper="leftSwiper"
          :right-swiper="rightSwiper"
        ></books-swiper>
        <books-list :book-list="bookList"></books-list>
        <div class="home-bottom" v-show="showDivider">
          <divider class="divider">已加载全部内容</divider>
        </div>
      </div>
    </scroll>
    <router-view></router-view>
  </div>
</template>

<script>
import Scroll from '@/components/scroll/scroll'
import BooksSwiper from './components/Swiper'
import BooksList from './components/List'
import { Divider, Toast } from 'vant'
import { getBooksList } from '@/api/Books'
import { mapGetters } from 'vuex'
import { getAdverts } from '@/api/Advert'

export default {
  name: 'Books',
  computed: {
    ...mapGetters([
      'agencyGuid'
    ])
  },
  components: {
    Scroll,
    BooksSwiper,
    BooksList,
    Divider
  },
  activated () {
    this.refresh()
  },
  mounted () {
    this._booksInit()
  },
  data () {
    return {
      swiperList: [], // 轮播列表
      leftSwiper: [], // 左轮播
      rightSwiper: [], // 右轮播
      page: 1,
      limit: 10,
      bookList: [],
      showDivider: false,
      isPullingDown: false
    }
  },
  methods: {
    _booksInit () {
      getAdverts({
        advert_type: 3,
        advert_agency_guid: this.agencyGuid
      }).then(res => {
        if (res.code === 200) {
          this.leftSwiper.push(res.data[res.data.length - 1])
          res.data.forEach((item, index) => {
            if (index !== res.data.length - 1) {
              this.leftSwiper.push(item)
            }
            if (index !== 0) {
              this.rightSwiper.push(item)
            }
          })
          this.rightSwiper.push(res.data[0])
          this.swiperList = res.data
        }
      })
      this._getBookList()
    },
    _getBookList () {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: '加载中...'
      })
      getBooksList({
        resources_class_agency: this.agencyGuid,
        page: this.page,
        limit: this.limit
      }).then(res => {
        if (res.code === 200) {
          this.bookList = this.bookList.concat(res.data.list)
          if (res.data.count <= this.bookList.length) {
            this.showDivider = true
          }
          Toast.clear()
        } else {
          Toast.fail(res.msg)
        }
      })
    },
    scroll (pos) {
      if (pos.y > 30) {
        this.isPullingDown = true
      } else if (pos.y <= 0) {
        this.isPullingDown = false
      }
    },
    pullDownRefresh () {
      this.page = 1
      this.bookList.splice(0, this.bookList.length)
      this.showDivider = false
      this._getBookList()
    },
    pullUpPush () {
      if (this.showDivider) {
        return true
      }
      this.page++
      this._getBookList()
    },
    refresh () {
      setTimeout(() => {
        this.$refs.scroll.refresh()
      }, 100)
    }
  }
}
</script>

<style lang='stylus' scoped>
.scroll
  position absolute
  top 0
  left 0
  right 0
  bottom 97px
  bottom calc(constant(safe-area-inset-bottom) + 97px)
  bottom calc(env(safe-area-inset-bottom) + 97px)
  overflow hidden

  .pull-down-wrapper
    display flex
    justify-content center
    align-items center
    width 750px
    height 70px
    margin-top -70px

    .pull-down-text
      line-height normal
      font-size 22px
      color rgba(153, 153, 153, 1)

  .home-bottom
    wdith 750px
    height 70px
    padding-top 30px

    .divider
      width 300px
      height 22px
      font-size 22px
      color rgba(153, 153, 153, 1)
      margin 0 auto
      border-color rgba(153, 153, 153, 1)
</style>
