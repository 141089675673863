import Base from './Base'

export function rank (data) {
  return Base.post('/api/v1/questions/rank', data)
}
export function agencyRank (data) {
  return Base.post('/api/v1/questions/agency_rank', data)
}
export function startGames (data) {
  return Base.post('/api/v1/questions/start_games', data)
}
export function gameOver (data) {
  return Base.post('/api/v1/questions/game_over', data)
}
export function setGameSec (data) {
  return Base.post('/api/v1/questions/set_game_sec', data)
}
export function useragency (data) {
  return Base.post('/api/v1/questions/useragency', data)
}
export function setagency (data) {
  return Base.post('/api/v1/questions/setagency', data)
}
