<template>
  <div class="sign-body">
    <div class="sign">
      <div class="sign-img" :style="sign_img_st"></div>
      <div class="sign-close" @click="close"></div>
    </div>
  </div>
</template>

<script>
import { getCurrentConnect } from '@/api/Activity'
import { mapGetters } from 'vuex'
export default {
  name: 'SignOverlay',
  data () {
    return {
      sign_img_st: ''
    }
  },
  created () {
    this._getCurrentConnect()
  },
  computed: {
    ...mapGetters([
      'userName',
      'agencyGuid',
      'userGuid'
    ])
  },
  methods: {
    close () {
      this.$emit('close')
    },
    _getCurrentConnect () {
      let _this = this
      let agencyGuid = localStorage.getItem('agencyGuid')
      getCurrentConnect({ activity_type_new: 1, agency_guid: agencyGuid, c_user_guid: this.userGuid }).then(res => {
        if (res.code === 200) {
          _this.sign_img_st = 'background-image:url(' + res.data.activity_success + ')'
        }
      })
    }
  }
}
</script>

<style scoped lang="stylus">
@import "~@/assets/stylus/mixin"
.sign-body
  position absolute
  z-index 3000
  display flex
  justify-content center
  width 100%
  height 100%

  .sign
    width 750px
    height 1000px

    .sign-img
      width 565px
      height 739px
      margin 300px auto 0 auto
      bg-image2('uploads/sgin-in/sign_dkcg202309')
      background-size 100% 100%
      background-repeat no-repeat

    .sign-close
      width 54px
      height 54px
      margin 30px auto 0 auto
      bg-image('~@/assets/img/sign-in/sign-close')
      background-size 100% 100%
      background-repeat no-repeat
</style>
